import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IVendorDataModel } from '../../models';
import { VendorsStoreFacade } from '../../store/facades';

@Component({
  selector: 'app-vendor-form',
  templateUrl: './vendor-form.component.html',
  styleUrls: ['./vendor-form.component.scss']
})
export class VendorFormComponent implements OnInit {

  public vendorForm: FormGroup;
  public userData: any;
  public vendorLink: string;
  public isVendorLoading: boolean;
  public vendorData: IVendorDataModel;
  

  public showValidationMessages = false;

  get vendorId() {
    return this.vendorForm!.get('vendorId');
  }

  constructor(
    private readonly vendorFacade: VendorsStoreFacade,
    private readonly snackBar: MatSnackBar
  ) { 
    this.vendorForm = new FormGroup({
      vendorId: new FormControl('',[
        Validators.required
      ])
    });
  }

  ngOnInit(): void {
    this.vendorFacade.isVendorLoading$.subscribe((data) => {
      console.log(data);
      this.isVendorLoading = data
    });
  }

  onContinue(formValue)
  {
    this.showValidationMessages = false;
    if(this.vendorForm.invalid)
    {
      this.showValidationMessages = true;
      return;
    }
    
    this.vendorFacade.queryVendorsByVendorId(formValue.vendorId);

    this.vendorFacade.selectAllVendors$.subscribe((data) => {
      if(data?.length > 0)
      {
        // const url = window.location.origin + `/vendor-form?id="${data[0].id}"`;
        this.vendorLink = data[0].email;
        
        this.vendorData = data[0];
        console.log(this.vendorData)
      }
    });

    this.vendorFacade.noVendorResult$.subscribe((data)=> {
      if(data && ! this.isVendorLoading)
      {
        this.snackBar.open('This is an invalid Vendor Id. Please contact takzin for further details.', "Close", {
          duration: 10000,
        }); 
      }
    })
    
  }

}
