import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ICommentDataModel, IUserModel } from '../../models';
import { CommentsStoreFacade } from '../../store/facades/comments.store-facade';
import { UsersStoreFacade } from '../../store/facades/users.store-facade';
import firebase from 'firebase/app';
import Timestamp = firebase.firestore.Timestamp;
import { formatDate } from '@angular/common';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UUID } from 'angular2-uuid';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmBoxComponent } from 'src/app/shared/components';

@Component({
  selector: 'app-comment-section',
  templateUrl: './comment-section.component.html',
  styleUrls: ['./comment-section.component.scss']
})
export class CommentSectionComponent implements OnInit {

  @Input()
  quoteIdentifier: string;

  public allUsers$: Observable<IUserModel[]>;
  public comments$: Observable<ICommentDataModel[]>;
  public commentForm: FormGroup;
  public editCommentForm: FormGroup;
  public loggedInUser: string;
  public editCommentId: string;

  public dateTimeFormatOptions = { month: "long", hour: "numeric", minute: "numeric", day: "numeric", year: "numeric" } as const;
  public locale = 'en-AE';

  get commentText() {
    return this.commentForm!.get('commentText');
  }

  get editCommentText() {
    return this.editCommentForm!.get('editCommentText');
  }

  constructor(
    private readonly commentsFacade: CommentsStoreFacade,
    private readonly userFacade: UsersStoreFacade,
    private readonly dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.loggedInUser = JSON.parse(localStorage.getItem('user')).uid;
    if(this.quoteIdentifier)
    {
      this.commentsFacade.queryCommentsByQuoteIdentifier(this.quoteIdentifier);
      this.comments$ = this.commentsFacade.selectAllComments$;
      this.allUsers$ = this.userFacade.selectAllUsers$;
    }

    this.commentForm = new FormGroup({
      commentText: new FormControl('',[
        Validators.required
      ])
    });

    this.editCommentForm = new FormGroup({
      editCommentText: new FormControl('',[
        Validators.required
      ])
    });
  }

  public getUserDisplayName(userId: string, users: IUserModel[]){
    const user = users.filter((ele) => ele.id == userId)[0];
    return user?.displayFullName;
  }

  public getFormattedDate(timestamp: Timestamp) {
    if (timestamp) {
      const date = new Date(formatDate(timestamp.toDate(), 'medium', this.locale));
      date.setHours(date.getHours() + 4);
      return date.toLocaleString(this.locale, this.dateTimeFormatOptions);
    }
    var dateinDubai = new Date(new Date().toUTCString().substr(0, 25));
    dateinDubai.setHours(dateinDubai.getHours() + 4);

    return dateinDubai.toLocaleString(this.locale, this.dateTimeFormatOptions);
  }

  onSubmit(formValue)
  {
    let loggedInUser = JSON.parse(localStorage.getItem('user'));
    let newComment: ICommentDataModel = {
      id: UUID.UUID(),
      commentText: formValue.commentText,
      createdBy: loggedInUser.uid,
      createdDate: firebase.firestore.Timestamp.fromDate(new Date(new Date().toUTCString().substr(0, 25)))
    };
    this.commentsFacade.createComment(this.quoteIdentifier, newComment);
    this.commentText.setValue('');
  }

  onEdit(comment)
  {
    this.editCommentId = comment.id;
    this.editCommentText.setValue(comment.commentText);
  }

  onDelete(commentId)
  {
    const confirmDialog = this.dialog.open(ConfirmBoxComponent, {
      disableClose: false,
    });
    confirmDialog.componentInstance.confirmMessageHeader = `Delete Comment`;
    confirmDialog.componentInstance.confirmMessageContent = `Are you sure you want to continue?`;
    confirmDialog.afterClosed().subscribe(result => {
      if (result) {
        this.commentsFacade.deleteComment(this.quoteIdentifier, commentId);
      }
    });
  }

  onCancel()
  {
    this.editCommentId = null;
  }

  onUpdate(formValue)
  {
    const confirmDialog = this.dialog.open(ConfirmBoxComponent, {
      disableClose: false,
    });
    confirmDialog.componentInstance.confirmMessageHeader = `Update`;
    confirmDialog.componentInstance.confirmMessageContent = `Are you sure you want to continue?`;
    confirmDialog.afterClosed().subscribe(result => {
      if (result) {
        this.commentsFacade.updateComment(this.quoteIdentifier, this.editCommentId, {
          commentText: this.editCommentText.value
        });
        this.onCancel();
      }
    });
    
  }

  getCommentText(comment: string)
  {
    return comment.replace(/\n/g, "<br />");
  }

}
