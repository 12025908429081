import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { ICommentDataModel } from "../../models";
import { CommentState } from "../reducers";
import * as fromComments from '../selectors/comments.selector';
import * as commentActions from '../actions/comments.action';

export interface ICommentsStoreFacade{
    selectAllComments$: Observable<ICommentDataModel[]>;

    queryCommentsByQuoteIdentifier(quoteIdentifier: string): void;
    createComment(quoteIdentifier: string, comment: ICommentDataModel): void;
    updateComment(quoteIdentifier: string, id:string, changes: Partial<ICommentDataModel>): void;
    deleteComment(quoteIdentifier: string, id:string): void;
}

@Injectable()
export class CommentsStoreFacade implements ICommentsStoreFacade{
    get selectAllComments$() {
        return this.store.select(fromComments.selectAll);
    }

    constructor(
        private readonly store: Store<CommentState>
    ){}

    queryCommentsByQuoteIdentifier(quoteIdentifier: string){
        this.store.dispatch(commentActions.queryCommentsByQuoteIdentifier({quoteIdentifier}))
    }

    createComment(quoteIdentifier: string, comment: ICommentDataModel){
        this.store.dispatch(commentActions.createComment({quoteIdentifier, comment}));
    }

    updateComment(quoteIdentifier:string, id:string, changes: Partial<ICommentDataModel>){
        this.store.dispatch(commentActions.updateComments({quoteIdentifier, id, changes}));
    }

    deleteComment(quoteIdentifier:string, id:string){
        this.store.dispatch(commentActions.deleteComments({quoteIdentifier, id}));
    }
    
}