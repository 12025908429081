<form class="search-form" [formGroup]="searchForm">
    <div class="container-fluid">
        <div class="row form-row">
            <div class="col-md-1 form-label-div">
                <label>
                                <b>Quote Id:</b>
                            </label>
            </div>
            <div class="col-md-2 form-group">
                <input type="text" id="quoteId" name="quoteId" formControlName="quoteId" class="form-control">
            </div>
            <div class="col-md-1 form-label-div">
                <label>
                                <b>Status:</b>
                            </label>
            </div>
            <div class="col-md-2 form-group">
                <select autofocus id="status" type="text" class="form-control modal-select" formControlName="status">
                    <option value="Select your option" disabled selected>Select your option</option>
                    <option value="new">New</option>
                    <option value="open">Open</option>
                    <option value="inprogress">In Progress</option>
                    <option value="awaitingresponse">Awaiting Response</option>
                    <option value="closed">Closed</option>
                </select>
            </div>
            <div class="col-md-1 form-label-div">
                <label>
                                <b>Quote Type:</b>
                            </label>
            </div>
            <div class="col-md-2 form-group">
                <select autofocus id="quoteType" type="text" class="form-control modal-select" formControlName="quoteType">
                    <option value="Select your option" disabled selected>Select your option</option>
                    <option value="storage">Storage</option>
                    <option value="transport">Transport</option>
                </select>
            </div>
            <div class="col-md-3 form-group">
                <div class="form-button-container">
                    <button type="button" (click)="applyFilter(searchForm.value)" class="takzin-button-primary takzin-modal-button" id="search-rates">Search</button>
                    <a (click)="onResetClick()" class="search-reset">Reset</a>
                </div>
            </div>
        </div>
    </div>
    <br />

</form>