import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from "@angular/fire/auth";
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UsersStoreFacade } from '../../store/facades/users.store-facade';
import { MatSnackBar } from '@angular/material/snack-bar';
import { QuotesStoreFacade } from '../../store/facades';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public loginForm: FormGroup;
  public userData: any;

  public showValidationMessages = false;

  get email() {
    return this.loginForm!.get('email');
  }

  get password() {
    return this.loginForm!.get('password');
  }

  constructor(
    private firebaseAuth: AngularFireAuth,
    private readonly router: Router,
    private readonly quoteFacade: QuotesStoreFacade,
    private readonly snackBar: MatSnackBar,
  ) { 
    this.loginForm = new FormGroup({
      email: new FormControl('',[
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')
      ]),
      password: new FormControl('',[
        Validators.required
      ]),
    });

    // this.userSubscription = this.firebaseAuth.authState.subscribe(user => {
    //   if (user) {
    //     this.userData = user;
    //     localStorage.setItem('user', JSON.stringify(this.userData));
    //     var result = JSON.parse(localStorage.getItem('user'));
    //     console.log(result);
    //   } else {
    //     localStorage.setItem('user', null);
    //     JSON.parse(localStorage.getItem('user'));
    //   }
    // })
  }

  ngOnInit(): void {
  }

  onLogin(formValue)
  {
    if(this.loginForm.invalid)
    {
      this.showValidationMessages = true;
      return;
    }
    this.quoteFacade.resetPage();
    this.firebaseAuth
    .signInWithEmailAndPassword(formValue.email, formValue.password)
    .then(value => {
      localStorage.setItem('user', JSON.stringify(value.user));
      // this.userFacade.queryUsers();
      this.router.navigate(['/']);
    })
    .catch(err => {
      this.snackBar.open(err+' Please contact the administrator.', "Close", {
        duration: 10000,
      });
    });    

    
  }

}
