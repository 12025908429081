import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { IFileUploadModel } from "../../models/file-upload.model";
import { AttachmentState } from "../reducers";
import * as fromAttachments from '../selectors/attachments.selector';
import * as attachmentsActions from '../actions/attachments.action';

export interface IAttachmentsStoreFacade{
    selectAllAttachments$: Observable<IFileUploadModel[]>;
    uploadProgress$: Observable<number>;
    attachmentLoading$: Observable<boolean>;
    noAttachmentsFound$: Observable<boolean>;

    queryAttachmentsByQuoteIdentifier(quoteIdentifier: string): void;
    createAttachment(quoteIdentifier: string, fileUpload: IFileUploadModel, file: File): void;
    deleteAttachments(quoteIdentifier: string, id:string,  name:string): void;
    updateAttachments(quoteIdentifier: string, id:string, changes: Partial<IFileUploadModel>): void;
}

@Injectable()
export class AttachmentsStoreFacade implements IAttachmentsStoreFacade{
    get selectAllAttachments$() {
        return this.store.select(fromAttachments.selectAll);
    }

    get uploadProgress$() {
        return this.store.select(fromAttachments.uploadProgress$);
    }

    get attachmentLoading$() {
        return this.store.select(fromAttachments.isAttachmentsLoading$);
    }

    get noAttachmentsFound$() {
        return this.store.select(fromAttachments.noAttachments$);
    }

    constructor(
        private readonly store: Store<AttachmentState>
    ){}

    queryAttachmentsByQuoteIdentifier(quoteIdentifier: string){
        this.store.dispatch(attachmentsActions.queryAttachmentsByQuoteIdentifier({quoteIdentifier}))
    }

    createAttachment(quoteIdentifier: string, attachment: IFileUploadModel, file: File){
        this.store.dispatch(attachmentsActions.createAttachment({quoteIdentifier, attachment, file}));
    }

    updateAttachments(quoteIdentifier:string, id:string, changes: Partial<IFileUploadModel>){
        this.store.dispatch(attachmentsActions.updateAttachments({quoteIdentifier, id, changes}));
    }

    deleteAttachments(quoteIdentifier:string, id:string, name:string){
        this.store.dispatch(attachmentsActions.deleteAttachments({quoteIdentifier, id, name}));
    }
    
}