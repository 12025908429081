import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { IVendorDataModel, VendorListFilter } from "../../models";
import { VendorState } from "../reducers";
import * as fromVendors from '../selectors/vendors.selector';
import * as vendorActions from '../actions/vendors.action';

export interface IVendorsStoreFacade{
    selectAllVendors$: Observable<IVendorDataModel[]>;
    noVendorResult$: Observable<boolean>;
    isVendorLoading$: Observable<boolean>;
    currentPage$: Observable<number>;
    lastQuoteCurrentPage$: Observable<IVendorDataModel>;
    firstQuoteCurrentPage$: Observable<IVendorDataModel>;
    nextPage$: Observable<boolean>;
    getFilter$:Observable<VendorListFilter>;

    queryVendorsByEmail(email: string): void;
    queryVendors(): void;
    createVendor(vendor: IVendorDataModel): void;
    updateVendor(id:string, changes: Partial<IVendorDataModel>): void;
    deleteVendor(id:string): void;
    nextPage(): void;
    prevPage(): void;
}

@Injectable()
export class VendorsStoreFacade implements IVendorsStoreFacade{
    get selectAllVendors$() {
        return this.store.select(fromVendors.selectAll);
    }

    get noVendorResult$() {
        return this.store.select(fromVendors.isVendorsNoResult$);
    }

    get isVendorLoading$() {
        return this.store.select(fromVendors.isVendorsLoading$);
    }

    get lastQuoteCurrentPage$(){
        return this.store.select(fromVendors.getlastQuoteInCurrentPage$);
    }

    get firstQuoteCurrentPage$(){
        return this.store.select(fromVendors.getfirstQuoteInCurrentPage$);
    }

    get currentPage$()
    {
        return this.store.select(fromVendors.getCurrentPage$);
    }

    get nextPage$()
    {
        return this.store.select(fromVendors.getNextPage$);
    }

    get getFilter$(){
        return this.store.select(fromVendors.getFilter$);
    }

    constructor(
        private readonly store: Store<VendorState>
    ){}

    queryVendorsByEmail(email: string){
        this.store.dispatch(vendorActions.queryVendorsByEmail({email}))
    }

    queryVendors(){
        this.store.dispatch(vendorActions.queryVendors())
    }

    createVendor(vendor: IVendorDataModel){
        this.store.dispatch(vendorActions.createVendor({vendor}));
    }

    updateVendor(id:string, changes: Partial<IVendorDataModel>){
        this.store.dispatch(vendorActions.updateVendors({id, changes}));
    }

    deleteVendor(id:string){
        this.store.dispatch(vendorActions.deleteVendors({id}));
    }

    nextPage(){
        this.store.dispatch(vendorActions.nextVendorPage());
    }

    prevPage(){
        this.store.dispatch(vendorActions.prevVendorPage());
    }
    
}