import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, map, mergeMap, switchMap, withLatestFrom } from "rxjs/operators";
import { IVendorDataModel } from "../../models";
import * as vendorActions from '../actions/vendors.action';
import { VendorsStoreFacade } from "../facades/vendors.store-facade";

@Injectable()
export class VendorsEffects{
    constructor(
        private readonly actions$: Actions,
        private readonly dfs: AngularFirestore,
        private readonly vendorFacade: VendorsStoreFacade
    ){}

    queryVendorsByEmail$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(vendorActions.queryVendorsByEmail),
            switchMap((action) => {
                // console.log(action);
                let response = this.dfs.collection<IVendorDataModel>(`vendors`, (ref) => {
                    return ref.where('email','==',action.email);
                }).stateChanges()
                return response;
            }),
            mergeMap(actions => {
                if(actions?.length > 0)
                {
                    return actions;
                }

                return of(vendorActions.noVendorResult())
                
            }),
            map(action => {
                // console.log(action.type)
                if(action.type == 'added')
                {
                    return vendorActions.addedVendors({vendor: action.payload.doc.data()})
                }
                if(action.type == 'modified')
                {
                    return vendorActions.modifiedVendors({vendor: action.payload.doc.data()})
                }
                if(action.type == 'removed')
                {
                    return vendorActions.removedVendors({vendor: action.payload.doc.data()})
                }
                return {
                    type: `${action.type}`,
                }
                 
            }),
            catchError(() => {
                return of(vendorActions.vendorError({error: 'error'}))
            })
        )
    });

    queryVendors$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(vendorActions.queryVendors),
            withLatestFrom(this.vendorFacade.getFilter$, this.vendorFacade.currentPage$, 
                this.vendorFacade.lastQuoteCurrentPage$, this.vendorFacade.firstQuoteCurrentPage$,
                this.vendorFacade.nextPage$),
            switchMap(([action, filter, page, lastQuote, firstQuote, nextPage]) => {
                // console.log(action);
                let response = this.dfs.collection<IVendorDataModel>(`vendors`, (ref) => {
                    let ref2: any = ref;
                    if(filter?.vendorId)
                    {
                        ref2 = ref2.where('vendorId', '==', filter.vendorId);
                    }

                if(nextPage)
                {
                    if(page < 2)
                    {
                        return ref2.orderBy('createdDate', 'desc').limit(10);
                    }
                    else{
                        return ref2.orderBy('createdDate', 'desc').startAfter(lastQuote.createdDate).limit(10);
                    }
                }
                else {
                    return ref2.orderBy('createdDate', 'desc').endBefore(firstQuote.createdDate).limitToLast(10);
                }
                }).stateChanges()
                return response;
            }),
            mergeMap(actions => {
                if(actions?.length > 0)
                {
                    return actions;
                }

                return of(vendorActions.noVendorResult())
                
            }),
            map(action => {
                // console.log(action.type)
                if(action.type == 'added')
                {
                    return vendorActions.addedVendors({vendor: action.payload.doc.data()})
                }
                if(action.type == 'modified')
                {
                    return vendorActions.modifiedVendors({vendor: action.payload.doc.data()})
                }
                if(action.type == 'removed')
                {
                    return vendorActions.removedVendors({vendor: action.payload.doc.data()})
                }
                return {
                    type: `${action.type}`,
                }
                 
            }),
            catchError(() => {
                return of(vendorActions.vendorError({error: 'error'}))
            })
        )
    });

    nextPage$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(vendorActions.nextVendorPage),
            switchMap(action => {
                return of(vendorActions.queryVendors())
            }),
            catchError((error) => {
                console.log(error);
                return of(vendorActions.vendorError({error: 'error'}))
            })
        )
    })

    prevPage$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(vendorActions.prevVendorPage),
            switchMap(action => {
                return of(vendorActions.queryVendors())
            }),
            catchError((error) => {
                console.log(error);
                return of(vendorActions.vendorError({error: 'error'}))
            })
        )
    })

    createVendor$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(vendorActions.createVendor),
            switchMap(data => {
                console.log('test')
                return this.dfs.collection(`vendors`).doc(data.vendor.id).set(Object.assign({}, data.vendor))
            }),
            map(() => vendorActions.createVendorSuccess()),
            catchError((error) => {
                console.log(error);
                return of(vendorActions.vendorError({error: 'error'}))
            })
        )
    });

    updateVendor$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(vendorActions.updateVendors),
            switchMap(data => {
                const ref = this.dfs.doc<IVendorDataModel>(`vendors/${data.id}`)
                return ref.update(data.changes)
                .then(() => {
                    return vendorActions.updateVendorsSuccess()
                })
                .catch(() => {
                    return vendorActions.vendorError({error: 'error'})
                })
            }),
            catchError((error) => {
                console.log(error);
                return of(vendorActions.vendorError({error: 'error'}))
            })
        )
    });

    deleteVendorQuote$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(vendorActions.deleteVendors),
            switchMap(data => {
                const ref = this.dfs.doc<IVendorDataModel>(`vendors/${data.id}`)
                return ref.delete()
                .then(() => {
                    return vendorActions.deleteVendorsSuccess()
                })
                .catch(() => {
                    return vendorActions.vendorError({error: 'error'})
                })
            }),
            catchError((error) => {
                console.log(error);
                return of(vendorActions.vendorError({error: 'error'}))
            })
        )
    });
}