import { createEntityAdapter, Dictionary, EntityState } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";
import { IWarehouseDetailDataModel } from "../../models";
import { addedWarehouseDetails, warehouseDetailError, modifiedWarehouseDetails, noWarehouseDetailResult, queryWarehouseDetails, removedWarehouseDetails, updateWarehouseDetails, updateWarehouseDetailsSuccess, queryWarehouseDetailsByVendorId } from "../actions";

export const warehouseDetailAdapter = createEntityAdapter<IWarehouseDetailDataModel>();

export interface WarehouseDetailState extends EntityState<IWarehouseDetailDataModel> {
    entities: Dictionary<IWarehouseDetailDataModel>;
    loading: boolean;
    error: boolean;
    noResult: boolean;
    updateSuccess: boolean;
}

export const initialWarehouseDetailState: WarehouseDetailState = warehouseDetailAdapter.getInitialState({
    entities: {},
    loading: false,
    error: false,
    noResult: false,
    updateSuccess: false
});

export const warehouseDetailsReducer = createReducer(
    initialWarehouseDetailState,
    on(queryWarehouseDetails, (state) => {
        return  {
            ...initialWarehouseDetailState,
            loading: true
        };
    }),

    on(queryWarehouseDetailsByVendorId, (state, {vendorIdentifier}) => {
        return {
            ...initialWarehouseDetailState,
            loading: true
        }
    }),

    on(addedWarehouseDetails, (state, { warehouseDetail }) => {
        return warehouseDetailAdapter.addOne(warehouseDetail, {
            ...state,
            loading: false
        });
    }),

    on(modifiedWarehouseDetails, (state, { warehouseDetail }) => {
        return warehouseDetailAdapter.updateOne({
            id: warehouseDetail.id,
            changes: warehouseDetail
        }, {
            ...state,
            loading: false
        })
    }),

    on(removedWarehouseDetails, (state, { warehouseDetail }) => {
        return warehouseDetailAdapter.removeOne(warehouseDetail.id, state);
    }),

    on(warehouseDetailError, (state, { error }) => {
        return  {
            ...state,
            error: 'There was something wrong. Please refresh the page and try again.' ?? error
        };
    }),

    on(noWarehouseDetailResult, (state) => {
        return {
            ...state,
            noResult: true,
            loading: false
        }
    }),

    on(updateWarehouseDetails, (state, {id, changes}) => {
        return {
            ...state,
            updateSuccess: false
        }
    }),

    on(updateWarehouseDetailsSuccess, (state) => {
        return {
            ...state,
            updateSuccess: true
        }
    })
);