import { ActionReducerMap } from '@ngrx/store';
import { vendorsReducer, VendorState } from './vendors.reducer';
import { warehouseDetailsReducer, WarehouseDetailState } from './warehouse-details.reducer';

export * from './vendors.reducer';
export * from './warehouse-details.reducer';

export interface CreateVendorDataState {
    vendors: VendorState,
    warehouseDetails: WarehouseDetailState
}

export const createSalesReducers: ActionReducerMap<CreateVendorDataState, any> = {
    vendors: vendorsReducer,
    warehouseDetails: warehouseDetailsReducer
}