import { createAction, props } from "@ngrx/store";
import { ICommentDataModel } from "../../models";

export const queryCommentsByQuoteIdentifier = createAction(
    '[Comments] query comments by Quote Identifier',
    props<{quoteIdentifier: string}>()
);

export const queryCommentsById = createAction(
    '[Comments] query Comments by Id',
    props<{id: string}>()
);

export const queryCommentsByIdSuccess = createAction(
    '[Users] query comment by Id Success',
    props<{comment: ICommentDataModel}>()
);

export const addedComments = createAction(
    '[Comments] added',
    props<{comment: ICommentDataModel}>()
);

export const createComment = createAction(
    '[Comments] create comment',
    props<{quoteIdentifier: string, comment: ICommentDataModel}>()
);

export const createCommentSuccess = createAction(
    '[Comments] create comment syccess'
);

export const modifiedComments = createAction(
    '[Comments] modified',
    props<{comment: ICommentDataModel}>()
);

export const updateComments = createAction(
    '[Comments] update',
    props<{quoteIdentifier: string, id:string, changes: Partial<ICommentDataModel>}>()
);

export const updateCommentsSuccess = createAction(
    '[Comments] update success'
);

export const deleteComments = createAction(
    '[Comments] delete',
    props<{quoteIdentifier: string, id:string}>()
);

export const deleteCommentsSuccess = createAction(
    '[Comments] delete success'
);

export const removedComments = createAction(
    '[Comments] removed',
    props<{comment: ICommentDataModel}>()
);

export const noCommentResult = createAction(
    '[Comments] no Comment Result'
);

export const commentError = createAction(
    '[Comments] comment Error',
    props<{error: any}>()
);