import { createAction, props } from "@ngrx/store";
import { IVendorDataModel } from "../../models";

export const queryVendorsByEmail = createAction(
    '[Vendors] query vendor by Email',
    props<{email: string}>()
);

export const queryVendorsById = createAction(
    '[Vendors] query Vendors by Id',
    props<{id: string}>()
);

export const queryVendors = createAction(
    '[Vendors] query Vendors'
);

export const queryVendorsByIdSuccess = createAction(
    '[Users] query vendor by Id Success',
    props<{vendor: IVendorDataModel}>()
);

export const addedVendors = createAction(
    '[Vendors] added',
    props<{vendor: IVendorDataModel}>()
);

export const createVendor = createAction(
    '[Vendors] create vendor',
    props<{vendor: IVendorDataModel}>()
);

export const createVendorSuccess = createAction(
    '[Vendors] create vendor syccess'
);

export const modifiedVendors = createAction(
    '[Vendors] modified',
    props<{vendor: IVendorDataModel}>()
);

export const updateVendors = createAction(
    '[Vendors] update',
    props<{id:string, changes: Partial<IVendorDataModel>}>()
);

export const updateVendorsSuccess = createAction(
    '[Vendors] update success'
);

export const deleteVendors = createAction(
    '[Vendors] delete',
    props<{id:string}>()
);

export const deleteVendorsSuccess = createAction(
    '[Vendors] delete success'
);

export const removedVendors = createAction(
    '[Vendors] removed',
    props<{vendor: IVendorDataModel}>()
);

export const noVendorResult = createAction(
    '[Vendors] no Vendor Result'
);

export const vendorError = createAction(
    '[Vendors] vendor Error',
    props<{error: any}>()
);

export const nextVendorPage = createAction(
    '[Vendors] next page'
);

export const prevVendorPage = createAction(
    '[Vendors] prev page'
);

export const resetVendorPage = createAction(
    '[Vendors] reset Page'
)

export const lastVendorInPage = createAction(
    '[Vendors] last Vendor In page',
    props<{vendor: IVendorDataModel}>()
);