import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IVendorDataModel } from '../../models';
import { VendorsStoreFacade } from '../../store/facades';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-vendor-detail',
  templateUrl: './vendor-detail.component.html',
  styleUrls: ['./vendor-detail.component.scss']
})
export class VendorDetailComponent implements OnInit {

  @Input()
  currentVendorData: IVendorDataModel;

  public vendorDetailForm: FormGroup;
  public showValidationMessages: boolean;

  get name() {
    return this.vendorDetailForm!.get('name');
  }

  get companyName() {
    return this.vendorDetailForm!.get('companyName');
  }

  get email() {
    return this.vendorDetailForm!.get('email');
  }

  get contactNo() {
    return this.vendorDetailForm!.get('contactNo');
  }

  constructor(
    private readonly vendorFacade: VendorsStoreFacade,
    private readonly snackBar: MatSnackBar,
  ) {
    this.vendorDetailForm = new FormGroup({
      name: new FormControl('',[
        Validators.required
      ]),
      companyName: new FormControl('',[
        Validators.required
      ]),
      email: new FormControl('',[
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')
      ]),
      contactNo: new FormControl('',[])
    })
   }

  ngOnInit(): void {
    this.onReset();
    this.vendorFacade.isVendorUpdateSuccess$.subscribe((data)=> {
      if(data)
      {
      this.snackBar.open('Your details has been updated successfully.', "Close", {
        duration: 3000,
      }); 
      }
    })
    
  }

  onSubmit(formValue)
  {
    this.showValidationMessages = false;
    if(this.vendorDetailForm.invalid)
    {
      this.showValidationMessages = true;
      return;
    }

    this.vendorFacade.updateVendor(this.currentVendorData.id, {
      name: formValue.name,
      companyName: formValue.companyName,
      email: formValue.email,
      contactNo: formValue.contactNo
    })

  }

  onReset(){
    this.vendorDetailForm.reset();
    this.showValidationMessages = false;
    this.email!.setValue(this.currentVendorData?.email);
    this.name!.setValue(this.currentVendorData?.name);
    this.companyName!.setValue(this.currentVendorData?.companyName);
    this.contactNo!.setValue(this.currentVendorData?.contactNo);
  }

}
