export const environment = {
    quotesApiBaseUrl: 'https://oo3c7f1rn1.execute-api.ap-south-1.amazonaws.com/develop/v1',
    firebaseConfig: {
      apiKey: "AIzaSyDqc7AScTAK5UZyPp6WWZ4XkeJak7vuAkA",
      authDomain: "takzindev.firebaseapp.com",
      databaseURL: "https://takzindev-default-rtdb.firebaseio.com",
      projectId: "takzindev",
      storageBucket: "takzindev.appspot.com",
      messagingSenderId: "503075510108",
      appId: "1:503075510108:web:a4c2a8c4748f33a7a3d399",
      measurementId: "G-35CBZPXKC9"
    },
    production: true
};