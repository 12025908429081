import { createEntityAdapter, Dictionary, EntityState } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";
import { IFileUploadModel } from "../../models/file-upload.model";
import { addedAttachments, attachmentError, createAttachmentSuccess, modifiedAttachments, noAttachmentResult, queryAttachmentsByQuoteIdentifier, removedAttachments } from "../actions";

export const attachmentAdapter = createEntityAdapter<IFileUploadModel>();

export interface AttachmentState extends EntityState<IFileUploadModel>{
    entities: Dictionary<IFileUploadModel>;
    error: boolean;
    loading: boolean;
    noResult: boolean;
    progress: number;
};

export const initialAttachmentState: AttachmentState = attachmentAdapter.getInitialState({
    entites: {},
    error: false,
    loading: false,
    noResult: false,
    progress: 0
});

export const attachmentsReducer = createReducer(
    initialAttachmentState,

    on(queryAttachmentsByQuoteIdentifier, (state, {quoteIdentifier}) => {
        return  {
            ...initialAttachmentState,
            loading: true
        };
    }),

    on(addedAttachments, (state, { attachment }) => {
        return attachmentAdapter.addOne(attachment, {
            ...state,
            loading: false,
            noResult: false
        });
    }),

    on(modifiedAttachments, (state, { attachment }) => {
        return attachmentAdapter.updateOne({
            id: attachment.id,
            changes: attachment
        }, {
            ...state,
            loading: false,
            noResult: false
        })
    }),

    on(removedAttachments, (state, { attachment }) => {
        return attachmentAdapter.removeOne(attachment.id, state);
    }),

    on(attachmentError, (state, { error }) => {
        return  {
            ...state,
            error: 'There was something wrong. Please refresh the page and try again.' ?? error
        };
    }),

    on(noAttachmentResult, (state) => {
        return {
            ...state,
            noResult: true,
            loading: false
        }
    }),

    on(createAttachmentSuccess, (state, {progress}) => {
        return {
            ...state,
            progress,
            noResult: false
        }
    })
);

