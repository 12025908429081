import { createAction, props } from "@ngrx/store";
import { IQuotesDataModel, IUserModel } from "../../models";

export const queryUsers = createAction(
    '[Users] query users'
);

export const queryUserById = createAction(
    '[Users] query user by Id',
    props<{userId: string}>()
);

export const queryUserByIdSuccess = createAction(
    '[Users] query user by Id Success',
    props<{user: IUserModel}>()
);

export const addedUsers = createAction(
    '[Users] added',
    props<{user: IUserModel}>()
);

export const createUser = createAction(
    '[Users] create User',
    props<{user: IUserModel}>()
);

export const createUserSuccess = createAction(
    '[Users] create User Success'
);

export const modifiedUsers = createAction(
    '[Users] modified',
    props<{user: IUserModel}>()
);

export const updateUsers = createAction(
    '[Users] update',
    props<{id:string, changes: Partial<IUserModel>}>()
);

export const updateUsersSuccess = createAction(
    '[Users] update success'
);

export const removedUser = createAction(
    '[Users] removed',
    props<{user: IUserModel}>()
);

export const selectUser = createAction(
    '[Users] select user',
    props<{selectedQuoteId: string}>()
);

export const userError = createAction(
    '[Users] user Error',
    props<{error: any}>()
);

export const noUserResult = createAction(
    '[Users] no User Result'
);