import { NgModule } from '@angular/core';
import { CommonModule, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { SalesHomeContainer } from './container';
import { SalesRoutingModule } from './sales-routing.module';
import { MatTableModule } from '@angular/material/table'
import { QuotesService } from '../services';
import { HttpClientModule } from '@angular/common/http';
import { QuotesStoreFacade } from './store/facades';
import { StoreModule } from '@ngrx/store';
import * as fromStore from './store';
import { EffectsModule } from '@ngrx/effects';
import { MatPaginatorModule } from '@angular/material/paginator';
import { LoadingComponent } from '../shared';
import { QuotesListComponent, QuotesDetailsComponent, SearchComponent, LoginComponent, UserProfileComponent, CommentSectionComponent, VendorDetailSectionComponent, QuoteAttachmentsComponent, VendorLinkComponent, VendorListComponent } from './components';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AngularFireModule } from '@angular/fire';
import { environment } from 'src/environments/environment';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { AuthGuard } from './guards';
import { AuthService } from './services';
import { UsersStoreFacade } from './store/facades/users.store-facade';
import { CommentsStoreFacade } from './store/facades/comments.store-facade';
import { VendorQuotesStoreFacade } from './store/facades/vendor-quotes.store-facade';
import { MatTabsModule } from '@angular/material/tabs';
import { AttachmentsStoreFacade } from './store/facades/attachments.store-facade';
import { VendorsStoreFacade } from './store/facades/vendors.store-facade';

@NgModule({
  declarations: [
    SalesHomeContainer,
    LoadingComponent,
    QuotesListComponent,
    QuotesDetailsComponent,
    SearchComponent,
    LoginComponent,
    UserProfileComponent,
    CommentSectionComponent,
    VendorDetailSectionComponent,
    QuoteAttachmentsComponent,
    VendorLinkComponent,
    VendorListComponent
  ],
  imports: [
    CommonModule,
    SalesRoutingModule,
    MatTableModule,
    HttpClientModule,
    MatPaginatorModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatSnackBarModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatTabsModule,
    StoreModule.forRoot({}),
    StoreModule.forFeature('sales', fromStore.createSalesReducers),
    EffectsModule.forRoot(),
    EffectsModule.forFeature(fromStore.effects),
    AngularFireModule.initializeApp(environment.firebaseConfig, 'takzin'),
    AngularFirestoreModule, // Only required for database features
    AngularFireAuthModule, // Only required for auth features,
    AngularFireStorageModule // Only required for storage features
  ],
  providers: [
    QuotesService,
    QuotesStoreFacade,
    UsersStoreFacade,
    CommentsStoreFacade,
    VendorQuotesStoreFacade,
    AttachmentsStoreFacade,
    VendorsStoreFacade,
    AuthGuard,
    AuthService,
    {provide : LocationStrategy , useClass: PathLocationStrategy  },
  ]
})
export class SalesModule { }
