import { createAction, props } from "@ngrx/store";
import { IUpdateQuotesDetailsDto } from "../../dtos";
import { QuoteListFilter } from "../../models";
import { IQuotesDataModel } from "../../models/quotes-data.model";

export const queryQuotes = createAction(
    '[Quotes] query quotes'
);

export const queryQuotesById = createAction(
    '[Quotes] query quotes by Id',
    props<{id: string}>()
);

export const queryQuotesByIdSuccess = createAction(
    '[Users] query quote by Id Success',
    props<{quote: IQuotesDataModel}>()
);

export const addedQuotes = createAction(
    '[Quotes] added',
    props<{quote: IQuotesDataModel}>()
);

export const createQuote = createAction(
    '[Quotes] create Quote',
    props<{quote: IQuotesDataModel}>()
);

export const modifiedQuotes = createAction(
    '[Quotes] modified',
    props<{quote: IQuotesDataModel}>()
);

export const updateQuotes = createAction(
    '[Quotes] update',
    props<{id:string, changes: Partial<IQuotesDataModel>}>()
);

export const updateQuotesSuccess = createAction(
    '[Quotes] update success'
);

export const removedQuotes = createAction(
    '[Quotes] removed',
    props<{quote: IQuotesDataModel}>()
);

export const selectQuote = createAction(
    '[Quotes] select quote',
    props<{selectedQuoteId: string}>()
);

export const error = createAction(
    '[Quotes] error',
    props<{error: any}>()
);

export const setFilter = createAction(
    '[Quotes] set filter',
    props<{filter: QuoteListFilter}>()
);

export const noResult = createAction(
    '[Quotes] no Result'
);

export const editQuoteForm = createAction(
    '[Quotes] edit Quote Form'
);

export const nextPage = createAction(
    '[Quotes] next page'
);

export const prevPage = createAction(
    '[Quotes] prev page'
);

export const resetPage = createAction(
    '[Quotes] reset Page'
)

export const lastQuoteInPage = createAction(
    '[Quotes] last Quote In page',
    props<{quote: IQuotesDataModel}>()
);

export const deleteQuotes = createAction(
    '[Quotes] delete',
    props<{id:string}>()
);

export const deleteQuotesSuccess = createAction(
    '[Quotes] delete success'
);