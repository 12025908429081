import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { QuoteListFilter } from '../../models';
import { QuotesStoreFacade } from '../../store/facades';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  public searchForm: FormGroup;
  public filterValue: QuoteListFilter;

  get quoteId() {
    return this.searchForm!.get('quoteId');
  }

  get status() {
    return this.searchForm!.get('status');
  }

  get quoteType() {
    return this.searchForm!.get('quoteType');
  }

  constructor(
    private readonly quotesFacade: QuotesStoreFacade
  ) { }

  ngOnInit(): void {
    this.quotesFacade.getFilter$.subscribe((data) => {
      if(data)
      {
        this.filterValue = data;
      }
    })
    this.searchForm = new FormGroup({
      quoteId: new FormControl(this.filterValue?.quoteId, []),
      status: new FormControl(this.filterValue?.status ? 
        this.filterValue.status :
        'Select your option', []),
      quoteType: new FormControl(this.filterValue?.quoteType ? 
        this.filterValue.quoteType :
        'Select your option', []),
    });
  }

  applyFilter(filterValue) {
    const filter: QuoteListFilter = {
      quoteId: filterValue.quoteId?.trim(),
      status: filterValue.status == 'Select your option' ? null : filterValue.status,
      quoteType: filterValue.quoteType == 'Select your option' ? null : filterValue.quoteType
    }
    this.quotesFacade.setFilter(filter);
    this.quotesFacade.queryQuotes();

  }

  onResetClick(){
    // this.searchForm.reset();
    // this.status.setValue('Select your option');
    // this.quoteType.setValue('Select your option');
    // this.quotesFacade.setFilter(null);
    // this.quotesFacade.resetPage();
    // this.quotesFacade.queryQuotes();
    window.location.reload()
  }

}
