<div class="container-fluid">
    <br /><br />
    <!-- <div class="row form-row">
        <div class="col-md-1 form-label-div">
            <label>
                            <b>Vendor Id:</b>
                        </label>
        </div>
        <div class="col-md-2 form-group">
            <input type="text" id="vendorId" name="vendorId" formControlName="vendorId" class="form-control">
        </div>
        <div class="col-md-3 form-group">
            <div class="form-button-container">
                <button type="button" (click)="applyFilter(searchForm.value)" class="takzin-button-primary takzin-modal-button" id="search-rates">Search</button>
                <a (click)="onResetClick()" class="search-reset">Reset</a>
            </div>
        </div>
    </div> -->
    <br /><br />
    <div class="row">
        <div class="col-md-12">
            <div class="button-container">
                <button type="button" (click)="addNewVendor()" class="takzin-button-primary takzin-modal-button add-new-quote">Add New Vendor</button>
                <button type="button" (click)="next()" class="takzin-button-primary takzin-modal-button next-page" *ngIf="vendors$ | async" [disabled]="(vendors$ | async).length<10">Next</button>
                <button type="button" (click)="prev()" class="takzin-button-primary takzin-modal-button next-page" *ngIf="vendors$ | async" [disabled]="(currentPage$ | async)<2">Prev</button>
                <span class="page-text" *ngIf="!isMobileBrowser()">{{(currentPage$ | async)>0 ? 'Page: '+(currentPage$ | async) : ''}}</span>
            </div>
            <mat-table [dataSource]="vendors$" class="vendor-table" *ngIf="!(isVendorLoading$ | async)">
                <ng-container matColumnDef="vendorId">
                    <mat-header-cell *matHeaderCellDef> Vendor Id </mat-header-cell>
                    <mat-cell *matCellDef="let element"> <a (click)="editVendor(element.id, element.vendorId)" class="vendor-link">{{element.vendorId}}</a> </mat-cell>
                </ng-container>

                <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.name}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="companyName">
                    <mat-header-cell *matHeaderCellDef> Company Name </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.companyName}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="createdWhen">
                    <mat-header-cell *matHeaderCellDef> Created Date </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{getFormattedDate(element.createdDate)}} </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let myRowData; columns: displayedColumns"></mat-row>
            </mat-table>
            <app-loading *ngIf="(isVendorLoading$ | async)"></app-loading>
            <br />
            <div *ngIf="noResult$ | async" class="no-results">No Results Found. Please click on <a (click)="onResetClick()" class="search-reset">Reset</a> to refresh the page and search filters.</div>
        </div>
    </div>
</div>