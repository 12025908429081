<div class="user-profile-container">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-4">&nbsp;</div>
            <div class="col-md-4 header-div">
                <h3 class="header-title ">ta<span class="primary-color">ḵ</span>zīn</h3>
                <br />
            </div>

        </div>
        <div class="row" *ngIf="!(isUserLoading$ | async)">
            <div class="col-md-4">&nbsp;</div>
            <div class="col-md-4 form-container">
                <form [formGroup]="userProfileForm" class="user-profile-form">
                    <div class="form-group">
                        <label class="takzin-modal-control-label required-label">
                                Full Display Name
                            </label>
                        <input type="text" value="" id="displayFullName" formControlName="displayFullName" class="form-control" required>
                        <div *ngIf="displayFullName!.invalid && showValidationMessages" class="takzin-validation-msg">
                            <div *ngIf="displayFullName!.errors">
                                Full Display Name is required.
                            </div>
                        </div>
                    </div>
                    <br />
                    <button type="button" (click)=onSubmit(userProfileForm.value) class="takzin-button-primary takzin-modal-button">Submit</button>
                </form>
            </div>
        </div>
        <app-loading *ngIf="isUserLoading$ | async"></app-loading>
    </div>
</div>