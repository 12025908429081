<header class="takzin-header">
    <div class=" header-div container-fluid">
        <!-- <div class="row">
            <div class="col-md-12">
                <h3 class="header-title "><a class="header-title" routerLink="/" routerLinkActive="active">ta<span class="primary-color">ḵ</span>zīn</a></h3>
            </div>
        </div> -->
        <nav class="container-fluid navbar fixed-top navbar-expand-lg navbar-light bg-white">
            <h3 class="header-title "><a class="header-title" routerLink="/" routerLinkActive="active">ta<span class="primary-color">ḵ</span>zīn</a></h3>
            <button *ngIf="!hideMenu" class="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                <div class= "navbar-button">
                    <span class="navbar-toggler-icon"></span>
                    <span class="close-icon">✖</span>
                </div>
          </button>

            <div *ngIf="!hideMenu" class="collapse navbar-collapse header-nav" id="navbarNavAltMarkup">
                <div class="nav navbar-nav ml-auto">
                    <a class="nav-item nav-link" routerLink="" alt="HOME" data-toggle="collapse" data-target=".navbar-collapse.show">HOME <span class="sr-only">(current)</span></a>
                    <a class="nav-item nav-link" (click)="goToProfile()" routerLinkActive="active" alt='PROFILE' data-toggle="collapse" data-target=".navbar-collapse.show">MY PROFILE</a>
                    <a class="nav-item nav-link" (click)="logout()" routerLinkActive="active" alt='LOGOUT' data-toggle="collapse" data-target=".navbar-collapse.show">LOGOUT</a>
                </div>
            </div>
        </nav>
    </div>
</header>