import { createFeatureSelector, createSelector } from "@ngrx/store";
import { CreateVendorDataState } from "..";
import { vendorAdapter, VendorState } from "../reducers";

export const getCreateVendorDataState = createFeatureSelector<CreateVendorDataState>('vendorData');
export const getVendorsState = createSelector(
    getCreateVendorDataState,
    (state: CreateVendorDataState) => state.vendors
);

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal
} = vendorAdapter.getSelectors(getVendorsState);

export const isVendorsLoading$ = createSelector(getVendorsState, (state: VendorState) => state.loading);
export const isVendorsError$ = createSelector(getVendorsState, (state: VendorState) => state.error);
export const isVendorsNoResult$ = createSelector(getVendorsState, (state: VendorState) => state.noResult);
export const isVendorUpdateSuccess$ = createSelector(getVendorsState, (state: VendorState) => state.updateSuccess);
