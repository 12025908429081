import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { IVendorDataModel } from "../../models";
import { VendorState } from "../reducers";
import * as fromVendors from '../selectors/vendors.selector';
import * as vendorActions from '../actions/vendors.action';

export interface IVendorsStoreFacade{
    selectAllVendors$: Observable<IVendorDataModel[]>;
    noVendorResult$: Observable<boolean>;
    isVendorLoading$: Observable<boolean>;
    isVendorUpdateSuccess$: Observable<boolean>;

    queryVendorsByEmail(email: string): void;
    createVendor(vendor: IVendorDataModel): void;
    updateVendor(id:string, changes: Partial<IVendorDataModel>): void;
    deleteVendor(id:string): void;
}

@Injectable()
export class VendorsStoreFacade implements IVendorsStoreFacade{
    get selectAllVendors$() {
        return this.store.select(fromVendors.selectAll);
    }

    get noVendorResult$() {
        return this.store.select(fromVendors.isVendorsNoResult$);
    }

    get isVendorLoading$() {
        return this.store.select(fromVendors.isVendorsLoading$);
    }

    get isVendorUpdateSuccess$() {
        return this.store.select(fromVendors.isVendorUpdateSuccess$);
    }

    constructor(
        private readonly store: Store<VendorState>
    ){}

    queryVendorsByEmail(email: string){
        this.store.dispatch(vendorActions.queryVendorsByEmail({email}))
    }

    queryVendorsByVendorId(vendorId: string){
        this.store.dispatch(vendorActions.queryVendorsByVendorId({vendorId}))
    }

    createVendor(vendor: IVendorDataModel){
        this.store.dispatch(vendorActions.createVendor({vendor}));
    }

    updateVendor(id:string, changes: Partial<IVendorDataModel>){
        this.store.dispatch(vendorActions.updateVendors({id, changes}));
    }

    deleteVendor(id:string){
        this.store.dispatch(vendorActions.deleteVendors({id}));
    }
    
}