<div class="takzin-app">
    <takzin-sales-header></takzin-sales-header>
    <div class="spacer">
        &nbsp;
    </div>
    <div>
        <router-outlet></router-outlet>
        <div class="back-to-top" (click)="scrollToTop()" *ngIf="windowScrolled">
            <i class="material-icons">north</i>
        </div>
    </div>
    <takzin-sales-footer></takzin-sales-footer>
</div>