<div class="container-fluid">
    <div class="row">
        <div class="col-md-12">
            <h1>{{confirmMessageHeader}}</h1>
            <div class="confirm-body">{{confirmMessageContent}}</div>
            <div>
                <button class="takzin-button-primary takzin-modal-button" (click)="dialogRef.close(true)">Confirm</button>
                <button class="takzin-button-secondary takzin-modal-button" (click)="dialogRef.close(false)">Cancel</button>
            </div>
        </div>
    </div>
</div>