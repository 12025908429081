import { createAction, props } from "@ngrx/store";
import { IVendorQuoteData } from "../../models";

export const queryVendorQuotesByQuoteIdentifier = createAction(
    '[Vendor Quotes] query vendor quotes by Quote Identifier',
    props<{quoteIdentifier: string}>()
);

export const addedVendorQuotes = createAction(
    '[Vendor Quotes] added',
    props<{vendorQuote: IVendorQuoteData}>()
);

export const createVendorQuote = createAction(
    '[Vendor Quotes] create Vendor Quote',
    props<{quoteIdentifier: string, vendorQuote: IVendorQuoteData}>()
);

export const createVendorQuoteSuccess = createAction(
    '[Vendor Quotes] create Vendor Quote success'
);

export const modifiedVendorQuotes = createAction(
    '[Vendor Quotes] modified',
    props<{vendorQuote: IVendorQuoteData}>()
);

export const updateVendorQuotes = createAction(
    '[Vendor Quotes] update',
    props<{quoteIdentifier: string, id:string, changes: Partial<IVendorQuoteData>}>()
);

export const updateVendorQuotesSuccess = createAction(
    '[Vendor Quotes] update success'
);

export const deleteVendorQuotes = createAction(
    '[Vendor Quotes] delete',
    props<{quoteIdentifier: string, id:string}>()
);

export const deleteVendorQuotesSuccess = createAction(
    '[Vendor Quotes] delete success'
);

export const removedVendorQuotes = createAction(
    '[Vendor Quotes] removed',
    props<{vendorQuote: IVendorQuoteData}>()
);

export const noVendorQuoteResult = createAction(
    '[Vendor Quotes] no Vendor Quote Result'
);

export const vendorQuoteError = createAction(
    '[Vendor Quotes] vendor Quote Error',
    props<{error: any}>()
);