import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UUID } from 'angular2-uuid';
import { IVendorQuoteData } from '../../models';
import firebase from 'firebase/app';
import Timestamp = firebase.firestore.Timestamp;
import { formatDate } from '@angular/common';
import { VendorQuotesStoreFacade } from '../../store/facades/vendor-quotes.store-facade';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmBoxComponent } from 'src/app/shared/components';

@Component({
  selector: 'app-vendor-detail-section',
  templateUrl: './vendor-detail-section.component.html',
  styleUrls: ['./vendor-detail-section.component.scss']
})
export class VendorDetailSectionComponent implements OnInit {
  vendorQuoteForm: FormGroup;
  editVendorQuoteForm: FormGroup;
  showValidationMessages: boolean;
  showEditValidationMessages: boolean;
  currentVendorQuoteId: string;
  vendorQuotes$: Observable<IVendorQuoteData[]>;

  @Input()
  quoteIdentifier: string;

  constructor(
    private readonly vendorFacade: VendorQuotesStoreFacade,
    private readonly dialog: MatDialog
  ) { }

  get warehouseName() {
    return this.vendorQuoteForm!.get('warehouseName');
  }

  get vendorName() {
    return this.vendorQuoteForm!.get('vendorName');
  }

  get email() {
    return this.vendorQuoteForm!.get('email');
  }

  get editWarehouseName() {
    return this.editVendorQuoteForm!.get('editWarehouseName');
  }

  get editVendorName() {
    return this.editVendorQuoteForm!.get('editVendorName');
  }

  get editEmail() {
    return this.editVendorQuoteForm!.get('editEmail');
  }

  get editContactNo() {
    return this.editVendorQuoteForm!.get('editContactNo');
  }

  get editRateCtc() {
    return this.editVendorQuoteForm!.get('editRateCtc');
  }

  get editRateQuoted() {
    return this.editVendorQuoteForm!.get('editRateQuoted');
  }

  ngOnInit(): void {
    this.vendorQuoteForm = new FormGroup({
      warehouseName: new FormControl('',[
        Validators.required
      ]),
      vendorName: new FormControl('',[
        Validators.required
      ]),
      contactNo: new FormControl('',[]),
      email: new FormControl('',[
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')
      ]),
      rateCtc: new FormControl('',[]),
      rateQuoted: new FormControl('',[])
    });

    this.editVendorQuoteForm = new FormGroup({
      editWarehouseName: new FormControl('',[
        Validators.required
      ]),
      editVendorName: new FormControl('',[
        Validators.required
      ]),
      editContactNo: new FormControl('',[]),
      editEmail: new FormControl('',[
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')
      ]),
      editRateCtc: new FormControl('',[]),
      editRateQuoted: new FormControl('',[])
    });
    this.vendorFacade.queryVendorQuotesByQuoteIdentifier(this.quoteIdentifier);
    this.vendorQuotes$ = this.vendorFacade.selectAllVendorQuotes$;
  }

  onSubmit(formValue)
  {
    console.log(this.vendorQuoteForm);
    this.showValidationMessages = false;
    if(this.vendorQuoteForm?.invalid)
    {
      this.showValidationMessages = true;
      return;
    }

    let loggedInUser = JSON.parse(localStorage.getItem('user'));
    let newVendorQuote: IVendorQuoteData = {
      id: UUID.UUID(),
      warehouseName: formValue.warehouseName,
      vendorName: formValue.vendorName,
      email: formValue.email,
      contactNo: formValue.contactNo,
      rateCtc: formValue.rateCtc,
      rateQuoted: formValue.rateQuoted,
      createdBy: loggedInUser.uid,
      createdDate: firebase.firestore.Timestamp.fromDate(new Date(new Date().toUTCString().substr(0, 25)))
    };
    this.vendorFacade.createVendorQuote(this.quoteIdentifier, newVendorQuote);
    this.vendorQuoteForm.reset();

  }

  onReset(){
    this.showValidationMessages = false;
    this.vendorQuoteForm.reset();
  }

  onEdit(vendorQuote)
  {
    this.currentVendorQuoteId = vendorQuote.id;
    this.editWarehouseName.setValue(vendorQuote.warehouseName);
    this.editVendorName.setValue(vendorQuote.vendorName);
    this.editContactNo.setValue(vendorQuote.contactNo);
    this.editEmail.setValue(vendorQuote.email);
    this.editRateCtc.setValue(vendorQuote.rateCtc);
    this.editRateQuoted.setValue(vendorQuote.rateQuoted);
  }

  onCancel()
  {
    this.currentVendorQuoteId = null;
    this.showValidationMessages = false;
    this.editVendorQuoteForm.reset();
  }

  onUpdate(vendorQuoteId, formValue)
  {
    this.showEditValidationMessages = false;
    if(this.editVendorQuoteForm.invalid)
    {
      this.showEditValidationMessages = true;
      return;
    }

    const confirmDialog = this.dialog.open(ConfirmBoxComponent, {
      disableClose: false,
    });
    confirmDialog.componentInstance.confirmMessageHeader = `Update Entry`;
    confirmDialog.componentInstance.confirmMessageContent = `Are you sure you want to continue?`;
    confirmDialog.afterClosed().subscribe(result => {
      if (result) {
        this.vendorFacade.updateVendorQuotes(this.quoteIdentifier, vendorQuoteId, {
          warehouseName: formValue.editWarehouseName,
          vendorName: formValue.editVendorName,
          email: formValue.editEmail,
          contactNo: formValue.editContactNo,
          rateCtc: formValue.editRateCtc,
          rateQuoted: formValue.editRateQuoted
        });
        this.onCancel();
      }
    });
    
  }

  onDelete(vendorQuoteId)
  {
    const confirmDialog = this.dialog.open(ConfirmBoxComponent, {
      disableClose: false,
    });
    confirmDialog.componentInstance.confirmMessageHeader = `Delete Entry`;
    confirmDialog.componentInstance.confirmMessageContent = `Are you sure you want to continue?`;
    confirmDialog.afterClosed().subscribe(result => {
      if (result) {
        this.vendorFacade.deleteVendorQuotes(this.quoteIdentifier, vendorQuoteId);
      }
    });
  }
}
