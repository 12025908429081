import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { IUserModel } from '../../models';
import { UsersStoreFacade } from '../../store/facades/users.store-facade';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {

  public userProfileForm: FormGroup;
  public currentUser: IUserModel;
  public isUserLoading$: Observable<boolean>;

  public showValidationMessages = false;

  get displayFullName() {
    return this.userProfileForm!.get('displayFullName');
  }

  constructor(
    private readonly router: Router,
    private readonly _activatedroute: ActivatedRoute,
    private readonly usersFacade: UsersStoreFacade
  ) { 
    
  }

  ngOnInit(): void {
    let loggedInUser = JSON.parse(localStorage.getItem('user'));
    this.usersFacade.queryUserById(loggedInUser.uid);
    this.isUserLoading$ = this.usersFacade.isUserLoading$;
    this.usersFacade.getCurrentUser$.subscribe((data) => {
      this.currentUser = data
      this.userProfileForm = new FormGroup({
        displayFullName: new FormControl(this.currentUser?.displayFullName,[
          Validators.required
        ])
      });
    });
  }

  onSubmit(formValue)
  {
    this.showValidationMessages = false;
    if(this.userProfileForm.invalid)
    {
      this.showValidationMessages = true;
      return;
    }
    
    this.usersFacade.updateUsers(this.currentUser.id, {
      id: this.currentUser.id,
      displayFullName: formValue.displayFullName
    });
    this.router.navigate(['/']);

    
  }

}
