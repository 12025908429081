import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { IQuotesDataModel, QuoteListFilter } from "../../models";
import * as fromQuotes from '../selectors/quotes.selector';
import * as quoteActions from "../actions/quotes.action";
import { QuoteState } from "../reducers/quotes.reducer";
import { QuotesEffects } from "../effects";


export interface IQuotesStoreFacade {
    selectAllQuotes$: Observable<IQuotesDataModel[]>;
    isLoadingQuotes$: Observable<boolean>;
    // selectedQuoteId$: Observable<string>;
    getError$: Observable<any>;
    getFilter$: Observable<QuoteListFilter>;
    noResult$: Observable<boolean>;
    getEditQuoteForm$: Observable<boolean>;
    currentQuote$: Observable<IQuotesDataModel>;
    currentPage$: Observable<number>;
    lastQuoteCurrentPage$: Observable<IQuotesDataModel>;
    firstQuoteCurrentPage$: Observable<IQuotesDataModel>;
    nextPage$: Observable<boolean>;

    queryQuotes(): void;
    queryQuoteById(quoteId: string): void;
    updateQuotes(id:string, changes: Partial<IQuotesDataModel>): void;
    selectQuoteId(selectedQuoteId: string): void;
    setFilter(filter: QuoteListFilter): void
    setEditQuoteForm(): void
    createQuote(quote: IQuotesDataModel): void;
    nextPage(): void;
    prevPage(): void;
    resetPage(): void;
    deleteQuote(id:string): void;
}

@Injectable()
export class QuotesStoreFacade implements IQuotesStoreFacade {
    
    get isLoadingQuotes$() {
        return this.store.select(fromQuotes.isLoadingQuotes$);
    }

    get selectAllQuotes$() {
        return this.store.select(fromQuotes.selectAll);
    }

    // get selectedQuoteId$() {
    //     return this.store.select(fromQuotes.selectedQuoteId$);
    // }

    get getError$(){
        return this.store.select(fromQuotes.error$);
    }

    get getFilter$(){
        return this.store.select(fromQuotes.getFilter$);
    }

    get noResult$(){
        return this.store.select(fromQuotes.noResult$);
    }

    get getEditQuoteForm$(){
        return this.store.select(fromQuotes.getEditQuoteForm$);
    }

    get currentQuote$(){
        return this.store.select(fromQuotes.getCurrentQuote$);
    }

    get lastQuoteCurrentPage$(){
        return this.store.select(fromQuotes.getlastQuoteInCurrentPage$);
    }

    get firstQuoteCurrentPage$(){
        return this.store.select(fromQuotes.getfirstQuoteInCurrentPage$);
    }

    get currentPage$()
    {
        return this.store.select(fromQuotes.getCurrentPage$);
    }

    get nextPage$()
    {
        return this.store.select(fromQuotes.getNextPage$);
    }

    constructor(
        private readonly store: Store<QuoteState>,
    ){}

    queryQuotes() {
        this.store.dispatch(quoteActions.queryQuotes());
    }

    queryQuoteById(id: string){
        this.store.dispatch(quoteActions.queryQuotesById({id}));
    }

    selectQuoteId(selectedQuoteId: string) {
        this.store.dispatch(quoteActions.selectQuote({selectedQuoteId}));
    }

    updateQuotes(id:string, changes: Partial<IQuotesDataModel>){
        this.store.dispatch(quoteActions.updateQuotes({id, changes}));
    }

    setFilter(filter: QuoteListFilter){
        this.store.dispatch(quoteActions.setFilter({filter}));
    }

    setEditQuoteForm(){
        this.store.dispatch(quoteActions.editQuoteForm());
    }

    createQuote(quote: IQuotesDataModel){
        this.store.dispatch(quoteActions.createQuote({quote}));
    }

    nextPage(){
        this.store.dispatch(quoteActions.nextPage());
    }

    prevPage(){
        this.store.dispatch(quoteActions.prevPage());
    }

    resetPage(){
        this.store.dispatch(quoteActions.resetPage());
    }

    deleteQuote(id:string){
        this.store.dispatch(quoteActions.deleteQuotes({id}));
    }
}