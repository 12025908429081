import { Component, OnInit, Output } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Observable } from 'rxjs';
import { IQuotesDataModel } from '../../models/quotes-data.model';
import { QuotesStoreFacade } from '../../store/facades';
import firebase from 'firebase/app';
import Timestamp = firebase.firestore.Timestamp;
import {formatDate} from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { IUserModel } from '../../models';
import { UsersStoreFacade } from '../../store/facades/users.store-facade';

@Component({
  selector: 'takzin-quotes-list',
  templateUrl: './quotes-list.component.html',
  styleUrls: ['./quotes-list.component.scss']
})
export class QuotesListComponent implements OnInit {
  public quotesData$: Observable<IQuotesDataModel[]>;
  public displayedColumns: string[] = ['quoteId', 'assignedTo', 'name', 'companyName', 'status', 'createdWhen', 'aging'];
  public totalRecords$: Observable<number>;
  public dateTimeFormatOptions = { month: "long", day: "numeric", year: "numeric" } as const;
  public locale = 'en-US';
  public pageNumber$: Observable<number>;
  public pageSize$: Observable<number>;
  public isLoading$: Observable<boolean>;
  public noResult$: Observable<boolean>;
  public isUserLoading$: Observable<boolean>;
  public allUsers$: Observable<IUserModel[]>;
  public currentPage$: Observable<number>;
  public statusDesc = {
    "open": "Open",
    "new": "New",
    "inprogress": "In Progress",
    "awaitingresponse": "Awaiting Response",
    "closed":"Closed"
  }

  constructor(
    private readonly quotesFacade: QuotesStoreFacade,
    private readonly router: Router,
    private readonly userFacade: UsersStoreFacade,
    private readonly _activatedRoute: ActivatedRoute
  ) { 
    if(this.isMobileBrowser())
    {
      this.displayedColumns = ['quoteId',  'assignedTo', 'status'];
    }
  }

  quotes$: Observable<any>;

  ngOnInit(): void {
    this.quotes$ = this.quotesFacade.selectAllQuotes$;
    this.isLoading$ = this.quotesFacade.isLoadingQuotes$;
    this.noResult$ = this.quotesFacade.noResult$;
    this.quotesFacade.resetPage();
    this.quotesFacade.queryQuotes();
    this.userFacade.queryUsers();
    this.isUserLoading$ = this.userFacade.isUserLoading$;
    this.allUsers$ = this.userFacade.selectAllUsers$;
    this.currentPage$ = this.quotesFacade.currentPage$;
  }

  onPageChanged(pageEvent: PageEvent)
  {
    // this.quotesFacade.changePageNumber(pageEvent.pageIndex + 1);
    // this.quotesFacade.changePageSize(pageEvent.pageSize);
    // this.quotesFacade.getAllQuotes();
  }

  getFormattedDate(timestamp: Timestamp){
    const date =  new Date(formatDate(timestamp.toDate(), 'medium', this.locale));
    date.setHours(date.getHours() + 4);
    return date.toLocaleString(this.locale, this.dateTimeFormatOptions);
  }

  getAge(timestamp: Timestamp){
    let dateSent =  new Date(formatDate(timestamp.toDate(), 'medium', this.locale));
    let currentDate = new Date();
    return Math.floor((Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()) - Date.UTC(dateSent.getFullYear(), dateSent.getMonth(), dateSent.getDate()) ) /(1000 * 60 * 60 * 24));
  }

  editQuote(id:string, quoteId: string)
  {
    this.quotesFacade.selectQuoteId(quoteId);
    this.router.navigate(['quote-details'], { queryParams: { quoteIdentifier: id }});
  }
  refreshList()
  {
    this.quotesFacade.queryQuoteById('tr-919135645');
  }

  isMobileBrowser(): boolean {
    return ( ( window.innerWidth <= 800 ) && ( window.innerHeight <= 1000 ) );
  }

  addNewQuote()
  {
    this.quotesFacade.setEditQuoteForm();
    this.router.navigate(['/quote-details']);
  }

  next(){
    this.quotesFacade.nextPage();
  }

  prev(){
    this.quotesFacade.prevPage();
  }

  onResetClick(){
    // this.searchForm.reset();
    // this.status.setValue('Select your option');
    // this.quoteType.setValue('Select your option');
    // this.quotesFacade.setFilter(null);
    // this.quotesFacade.resetPage();
    // this.quotesFacade.queryQuotes();
    window.location.reload()
  }

  public getUserDisplayName(userId: string, users: IUserModel[]){
    const user = users.filter((ele) => ele.id == userId)[0];
    return user?.displayFullName;
  }

}
