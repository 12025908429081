import { createFeatureSelector, createSelector } from "@ngrx/store";
import { CreateSalesState } from "..";
import { attachmentAdapter, AttachmentState } from "../reducers";

export const getCreateSalesState = createFeatureSelector<CreateSalesState>('sales');
export const getAttachmentsState = createSelector(
    getCreateSalesState,
    (state: CreateSalesState) => state.attachments
);

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal
} = attachmentAdapter.getSelectors(getAttachmentsState);

export const isAttachmentsLoading$ = createSelector(getAttachmentsState, (state: AttachmentState) => state.loading);
export const isAttachmentsError$ = createSelector(getAttachmentsState, (state: AttachmentState) => state.error);
export const uploadProgress$ = createSelector(getAttachmentsState, (state: AttachmentState) => state.progress);
export const noAttachments$ = createSelector(getAttachmentsState, (state: AttachmentState) => state.noResult);
