<div class="container-fluid vendor-detail-container">

    <form [formGroup]="vendorDetailForm" class="vendor-detail-form">
        <div class="row">
            <div class="col-md-1 form-label-div">
                <label class="required-label">
                        <b>Name:</b>
                    </label>
            </div>
            <div class="col-md-3 form-group">
                <input type="text" value="" id="name" formControlName="name" class="form-control">
                <div *ngIf="name!.invalid && showValidationMessages && name!.errors!.required" class="takzin-validation-msg">
                    <div *ngIf="name!.errors!.required">
                        Name is required.
                    </div>
                </div>
            </div>


            <div class="col-md-1 form-label-div">
                <label class="required-label">
                        <b>Company Name:</b>
                    </label>
            </div>
            <div class="col-md-3 form-group">
                <input type="text" value="" id="companyName" formControlName="companyName" class="form-control">
                <div *ngIf="companyName!.invalid && showValidationMessages && companyName!.errors!.required" class="takzin-validation-msg">
                    <div *ngIf="companyName!.errors!.required">
                        Company Name is required.
                    </div>
                </div>
            </div>


            <div class="col-md-1 form-label-div">
                <label class="required-label">
                        <b>Email:</b>
                    </label>
            </div>
            <div class="col-md-3 form-group">
                <input type="text" value="" id="email" formControlName="email" class="form-control">
                <div *ngIf="email!.invalid && showValidationMessages && email!.errors!" class="takzin-validation-msg">
                    <div *ngIf="email!.errors!.required">
                        Vendor Email is required.
                    </div>
                    <div *ngIf="email!.errors!.pattern">
                        Vendor Email is invalid.
                    </div>
                </div>
            </div>


            <div class="col-md-1 form-label-div">
                <label>
                        <b>Vendor Contact No.:</b>
                    </label>
            </div>
            <div class="col-md-3 form-group">
                <input type="text" value="" id="contactNo" formControlName="contactNo" class="form-control">
            </div>
            <div class="col-md-2">
                <button type="button" (click)=onSubmit(vendorDetailForm.value) class="takzin-button-primary takzin-modal-button">Save</button>
                <button type="button" (click)=onReset() class="takzin-button-secondary takzin-modal-button">Reset</button>
            </div>
        </div>
    </form>

</div>