import { ActionReducerMap } from '@ngrx/store';
import { attachmentsReducer, AttachmentState } from './attachments.reducer';
import { commentsReducer, CommentState } from './comments.reducer';
import { quotesReducer, QuoteState } from './quotes.reducer';
import { usersReducer, UserState } from './users.reducer';
import { vendorQuotesReducer, VendorQuoteState } from './vendor-quotes.reducer';
import { vendorsReducer, VendorState } from './vendors.reducer';

export * from './quotes.reducer';
export * from './users.reducer';
export * from './comments.reducer';
export * from './vendor-quotes.reducer';
export * from './attachments.reducer';
export * from './vendors.reducer';

export interface CreateSalesState {
    quotes: QuoteState,
    users: UserState,
    comments: CommentState,
    vendorQuotes: VendorQuoteState,
    attachments: AttachmentState,
    vendors: VendorState
}

export const createSalesReducers: ActionReducerMap<CreateSalesState, any> = {
    quotes: quotesReducer,
    users: usersReducer,
    comments: commentsReducer,
    vendorQuotes: vendorQuotesReducer,
    attachments: attachmentsReducer,
    vendors: vendorsReducer
}