import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { IQuotesDataModel } from '../../models/quotes-data.model';
import { QuotesStoreFacade } from '../../store/facades';
import { UsersStoreFacade } from '../../store/facades/users.store-facade';

@Component({
  selector: 'app-sales-home',
  templateUrl: './sales-home.container.html',
  styleUrls: ['./sales-home.container.scss']
})

export class SalesHomeContainer implements OnInit {
  public selectedQuoteId$: Observable<string>;
  public editQuoteForm$: Observable<boolean>;
  public isUserLoading: boolean;
  public completeUserLoggedIn = false;

  constructor(
    private readonly quotesFacade: QuotesStoreFacade,
    private readonly usersFacade: UsersStoreFacade,
    private _snackBar: MatSnackBar,
    @Inject(PLATFORM_ID) private platformId: any,
    private readonly router: Router
  ) { }

  ngOnInit(): void {
    // this.usersFacade.queryUsers();
    // this.usersFacade.isUserLoading$.subscribe((data) => this.isUserLoading = data);
    // let loggedInUser = JSON.parse(localStorage.getItem('user'));
    // this.usersFacade.selectAllUsers$.subscribe((data) => {
    //   console.log(data);
    //   if(!this.isUserLoading)
    //   {
    //     const filteredUser = data.filter(f => f.id == loggedInUser.uid)[0];
    //     if(filteredUser)
    //     {
    //       this.completeUserLoggedIn = true;
    //     }
    //     else{
    //       this.router.navigate(['user-profile',loggedInUser.uid]);
    //     }
    //   }
    // })
    // this.selectedQuoteId$ = this.quotesFacade.selectedQuoteId$;
    // this.editQuoteForm$ = this.quotesFacade.getEditQuoteForm$;

    // this.selectedQuoteId$.subscribe(() => {
    //   this.scrollToTop();
    // });

    this.quotesFacade.getError$.subscribe((error) => {
      if(error)
      {
        this._snackBar.open(error, "Close", {
          duration: 20000,
        });
      }
    });
  }

  scrollToTop()
  {
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo({top: 0})
    }
  }

}
