<div class="container-fluid">
    <div class="row">
        <div class="col-md-12">
            <input #file type="file" id="attachment" (change)="onFileChange($event)" style="display: none;" (click)="OnFileClick($event)">
            <button type="button" for="attachment" class="takzin-button-primary takzin-modal-button" (click)="file.click()">Attach</button> Please Click Attach and select a file to upload.
            <div *ngIf="errorMessage" class="takzin-validation-msg">
                <div *ngIf="errorMessage">
                    {{errorMessage}}
                </div>
            </div>
        </div>
    </div>
    <br />
    <div class="row">
        <div class="col-md-12" *ngIf="currentFileUpload">
            <div class="progress-bar progress-bar-info" role="progressbar" attr.aria-valuenow="{{ progress }}" aria-valuemin="0" aria-valuemax="100" [ngStyle]="{ width: progress + '%' }">
                {{ progress }}%
            </div>
        </div>
    </div>
    <br />
    <div class="row">
        <div class="col-md-12">
            <mat-table [dataSource]="attachments$" class="attachments-table">
                <ng-container matColumnDef="fileName">
                    <mat-header-cell *matHeaderCellDef> File Name </mat-header-cell>
                    <mat-cell *matCellDef="let element"> <a href="{{element.url}}" target="_blank" class="quotes-link">{{element.name}}</a> </mat-cell>
                </ng-container>

                <ng-container matColumnDef="createdBy">
                    <mat-header-cell *matHeaderCellDef> Uploaded By </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{getUserDisplayName(element.createdBy,(allUsers$ | async))}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="createdDate">
                    <mat-header-cell *matHeaderCellDef> Uploaded Date </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{getFormattedDate(element.createdDate)}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="action">
                    <mat-header-cell *matHeaderCellDef> </mat-header-cell>
                    <mat-cell *matCellDef="let element"> <a (click)="deleteAttachment(element)" class="quotes-link">Delete</a></mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let myRowData; columns: displayedColumns"></mat-row>
            </mat-table>
            <app-loading *ngIf="(isAttachmentLoading$ | async) || (isUserLoading$ | async)"></app-loading>
            <div *ngIf="noResult$ | async" class="no-results">No files uploaded.</div>
        </div>

    </div>
</div>