import { createEntityAdapter, Dictionary, EntityState } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";
import { ICommentDataModel } from "../../models";
import { addedComments, commentError, modifiedComments, noCommentResult, queryCommentsByQuoteIdentifier, removedComments } from "../actions";

export const commentAdapter = createEntityAdapter<ICommentDataModel>();

export interface CommentState extends EntityState<ICommentDataModel> {
    entities: Dictionary<ICommentDataModel>;
    loading: boolean;
    error: boolean;
    noResult: boolean;
}

export const initialCommentState: CommentState = commentAdapter.getInitialState({
    entities: {},
    loading: false,
    error: false,
    noResult: false
});

export const commentsReducer = createReducer(
    initialCommentState,
    on(queryCommentsByQuoteIdentifier, (state, {quoteIdentifier}) => {
        return  {
            ...initialCommentState,
            loading: true
        };
    }),

    on(addedComments, (state, { comment }) => {
        return commentAdapter.addOne(comment, {
            ...state,
            loading: false
        });
    }),

    on(modifiedComments, (state, { comment }) => {
        return commentAdapter.updateOne({
            id: comment.id,
            changes: comment
        }, {
            ...state,
            loading: false
        })
    }),

    on(removedComments, (state, { comment }) => {
        return commentAdapter.removeOne(comment.id, state);
    }),

    on(commentError, (state, { error }) => {
        return  {
            ...state,
            error: 'There was something wrong. Please refresh the page and try again.' ?? error
        };
    }),

    on(noCommentResult, (state) => {
        return {
            ...state,
            noResult: true
        }
    }),
);