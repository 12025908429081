import { createFeatureSelector, createSelector } from "@ngrx/store";
import { CreateSalesState } from "../reducers";
import { userAdapter, UserState } from "../reducers/users.reducer";

export const getCreateSalesState = createFeatureSelector<CreateSalesState>('sales');
export const getUsersState = createSelector(
    getCreateSalesState,
    (state: CreateSalesState) => state.users
);

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal
} = userAdapter.getSelectors(getUsersState);

export const isUserLoading$ = createSelector(getUsersState, (state: UserState) => state.userLoading);

export const getCurrentUser$ = createSelector(getUsersState, (state: UserState) => state.currentUser);