import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { IVendorQuoteData } from "../../models";
import { VendorQuoteState } from "../reducers";
import * as fromVendorQuotes from '../selectors/vendor-quotes.selector';
import * as vendorQuoteActions from '../actions/vendor-quotes.action';

export interface IVendorQuotesStoreFacade{
    selectAllVendorQuotes$: Observable<IVendorQuoteData[]>;

    queryVendorQuotesByQuoteIdentifier(quoteIdentifier: string): void;
    createVendorQuote(quoteIdentifier: string, comment: IVendorQuoteData): void;
    updateVendorQuotes(quoteIdentifier: string, id:string, changes: Partial<IVendorQuoteData>): void;
    deleteVendorQuotes(quoteIdentifier: string, id:string): void;
}

@Injectable()
export class VendorQuotesStoreFacade implements IVendorQuotesStoreFacade{
    get selectAllVendorQuotes$() {
        return this.store.select(fromVendorQuotes.selectAll);
    }

    constructor(
        private readonly store: Store<VendorQuoteState>
    ){}

    queryVendorQuotesByQuoteIdentifier(quoteIdentifier: string){
        this.store.dispatch(vendorQuoteActions.queryVendorQuotesByQuoteIdentifier({quoteIdentifier}))
    }

    createVendorQuote(quoteIdentifier: string, vendorQuote: IVendorQuoteData){
        this.store.dispatch(vendorQuoteActions.createVendorQuote({quoteIdentifier, vendorQuote}));
    }

    updateVendorQuotes(quoteIdentifier:string, id:string, changes: Partial<IVendorQuoteData>){
        this.store.dispatch(vendorQuoteActions.updateVendorQuotes({quoteIdentifier, id, changes}));
    }

    deleteVendorQuotes(quoteIdentifier:string, id:string){
        this.store.dispatch(vendorQuoteActions.deleteVendorQuotes({quoteIdentifier, id}));
    }
    
}