<div class="container-fluid comments-container">


    <div class=" row comment-row " *ngFor="let comment of comments$ | async ">
        <div class="col-md-12">
            <hr />
        </div>
        <div class="col-md-12 comment-context " *ngIf="comment.id !== editCommentId">
            <div class="content-comment">{{comment.commentText}}</div>
        </div>
        <div class="col-md-12 comment-context " *ngIf="comment.id === editCommentId">
            <form [formGroup]="editCommentForm" class="comment-form">
                <textarea type="text" value="" id="editCommentText" formControlName="editCommentText" class="form-control comment-text-area" row="6"></textarea>
            </form>
        </div>
        <div class="col-md-4 comment-footer comment-footer-date ">
            Created Date: {{getFormattedDate(comment.createdDate)}}
        </div>
        <div class="col-md-4 comment-footer comment-footer-creator ">
            Created By: {{getUserDisplayName(comment.createdBy, allUsers$ | async)}}
        </div>
        <div class="col-md-4 comment-footer comment-footer-creator" *ngIf="(comment.createdBy === loggedInUser) && (editCommentId !== comment.id)">
            <button type="button" (click)=onEdit(comment) class="takzin-button-primary takzin-modal-button">Edit</button>
            <button type="button" (click)=onDelete(comment.id) class="takzin-button-secondary takzin-modal-button">Delete</button>
        </div>

        <div class="col-md-4 comment-footer comment-footer-creator" *ngIf="(comment.createdBy === loggedInUser) && (editCommentId === comment.id)">
            <button type="button" (click)=onUpdate(comment) class="takzin-button-primary takzin-modal-button" [disabled]="(editCommentText.value.trim() === '')">Update</button>
            <button type="button" (click)=onCancel() class="takzin-button-secondary takzin-modal-button">Cancel</button>
        </div>
    </div>

    <div class="row comment-form-container">
        <div class="col-md-12">
            <form [formGroup]="commentForm" class="comment-form">
                <textarea type="text" value="" id="commentText" formControlName="commentText" class="form-control comment-text-area" row="6"></textarea>
            </form>
            <br />
            <button type="button" (click)=onSubmit(commentForm.value) class="takzin-button-primary takzin-modal-button" [disabled]="(commentText.value.trim() === '')">Add Comment</button>
            <br />
        </div>
    </div>
</div>