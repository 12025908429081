import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UUID } from 'angular2-uuid';
import { IVendorDataModel } from '../../models';
import { VendorsStoreFacade } from '../../store/facades/vendors.store-facade';
import firebase from 'firebase/app';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-vendor-link',
  templateUrl: './vendor-link.component.html',
  styleUrls: ['./vendor-link.component.scss']
})
export class VendorLinkComponent implements OnInit {

  public vendorLinkForm: FormGroup;
  public userData: any;
  public vendorLink: string;
  public isVendorLoading: boolean;

  public showValidationMessages = false;

  get email() {
    return this.vendorLinkForm!.get('email');
  }

  constructor(
    private readonly vendorFacade: VendorsStoreFacade,
    private readonly router: Router
  ) { 
    this.vendorLinkForm = new FormGroup({
      email: new FormControl('',[
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')
      ])
    });
  }

  ngOnInit(): void {
    this.vendorFacade.isVendorLoading$.subscribe((data) => this.isVendorLoading = data);
  }

  onGenerate(formValue)
  {
    let loggedInUser = JSON.parse(localStorage.getItem('user'));
    if(this.vendorLinkForm.invalid)
    {
      this.showValidationMessages = true;
      return;
    }
    
    this.vendorFacade.queryVendorsByEmail(formValue.email);

    this.vendorFacade.selectAllVendors$.subscribe((data) => {
      if(data?.length > 0)
      {
        // const url = window.location.origin + `/vendor-form?id="${data[0].id}"`;
        this.vendorLink = data[0].vendorId;

        console.log('got data');
      }
    });

    this.vendorFacade.noVendorResult$.subscribe((data)=> {
      if(data && ! this.isVendorLoading)
      {
        console.log('no data');
        let id = UUID.UUID();
        let vendorId = 'tv-'+this.getRandomNumber()
        const url = window.location.origin + `/vendor-form?id="${id}"`;
        this.vendorLink = vendorId;
        let newVendor: IVendorDataModel = {
          id: id,
          vendorId: vendorId,
          email: formValue.email,
          createdBy: loggedInUser.uid,
          createdDate: firebase.firestore.Timestamp.fromDate(new Date(new Date().toUTCString().substr(0, 25)))
        }
        this.vendorFacade.createVendor(newVendor);
      }
    })
    
  }

  goBack()
  {
    this.router.navigate(['vendor-list']);
  }

  private getRandomNumber(): number{
    return Math.floor(Math.random() * 1000000000);
  }

}
