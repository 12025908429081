import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { IVendorDataModel } from '../../models';
import { VendorsStoreFacade } from '../../store/facades/vendors.store-facade';
import firebase from 'firebase/app';
import Timestamp = firebase.firestore.Timestamp;
import {formatDate} from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-vendor-list',
  templateUrl: './vendor-list.component.html',
  styleUrls: ['./vendor-list.component.scss']
})
export class VendorListComponent implements OnInit {
  public vendors$: Observable<IVendorDataModel[]>;
  public isVendorLoading$: Observable<boolean>;
  public displayedColumns: string[] = ['vendorId', 'name', 'companyName', 'createdWhen'];
  public noResult$: Observable<boolean>;
  public dateTimeFormatOptions = { month: "long", day: "numeric", year: "numeric" } as const;
  public locale = 'en-US';
  public currentPage$: Observable<number>;

  constructor(
    private readonly vendorFacade: VendorsStoreFacade,
    private readonly router: Router
  ) { }

  ngOnInit(): void {
    this.vendors$ = this.vendorFacade.selectAllVendors$;
    this.vendors$.subscribe((data)=> {
      console.log(data);
    })
    this.vendorFacade.queryVendors();
    this.isVendorLoading$ = this.vendorFacade.isVendorLoading$;
    this.noResult$ = this.vendorFacade.noVendorResult$;
  }

  getFormattedDate(timestamp: Timestamp){
    const date =  new Date(formatDate(timestamp.toDate(), 'medium', this.locale));
    date.setHours(date.getHours() + 4);
    return date.toLocaleString(this.locale, this.dateTimeFormatOptions);
  }

  onResetClick(){
    window.location.reload()
  }

  editVendor(id:string, vendorId: string)
  {
    this.router.navigate(['vendor-details'], { queryParams: { vendorIdentifier: id }});
  }

  isMobileBrowser(): boolean {
    return ( ( window.innerWidth <= 800 ) && ( window.innerHeight <= 1000 ) );
  }

  next(){
    // this.quotesFacade.nextPage();
  }

  prev(){
    // this.quotesFacade.prevPage();
  }

  addNewVendor()
  {
    this.router.navigate(['vendor-link']);
  }

}
