import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, map, mergeMap, switchMap } from "rxjs/operators";
import { IVendorDataModel } from "../../models";
import * as vendorActions from '../actions/vendors.action';

@Injectable()
export class VendorsEffects{
    constructor(
        private readonly actions$: Actions,
        private readonly dfs: AngularFirestore
    ){}

    queryVendorsByQuoteIdentifier$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(vendorActions.queryVendorsByEmail),
            switchMap((action) => {
                // console.log(action);
                let response = this.dfs.collection<IVendorDataModel>(`vendors/`, (ref) => {
                    return ref.where('email','==',action.email);
                }).stateChanges()
                return response;
            }),
            mergeMap(actions => {
                if(actions?.length > 0)
                {
                    return actions;
                }

                return of(vendorActions.noVendorResult())
                
            }),
            map(action => {
                // console.log(action.type)
                if(action.type == 'added')
                {
                    return vendorActions.addedVendors({vendor: action.payload.doc.data()})
                }
                if(action.type == 'modified')
                {
                    return vendorActions.modifiedVendors({vendor: action.payload.doc.data()})
                }
                if(action.type == 'removed')
                {
                    return vendorActions.removedVendors({vendor: action.payload.doc.data()})
                }
                return {
                    type: `${action.type}`,
                }
                 
            }),
            catchError(() => {
                return of(vendorActions.vendorError({error: 'error'}))
            })
        )
    });

    queryVendorsByVendorId$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(vendorActions.queryVendorsByVendorId),
            switchMap((action) => {
                // console.log(action);
                let response = this.dfs.collection<IVendorDataModel>(`vendors/`, (ref) => {
                    return ref.where('vendorId','==',action.vendorId);
                }).stateChanges()
                return response;
            }),
            mergeMap(actions => {
                if(actions?.length > 0)
                {
                    return actions;
                }

                return of(vendorActions.noVendorResult())
                
            }),
            map(action => {
                // console.log(action.type)
                if(action.type == 'added')
                {
                    return vendorActions.addedVendors({vendor: action.payload.doc.data()})
                }
                if(action.type == 'modified')
                {
                    return vendorActions.modifiedVendors({vendor: action.payload.doc.data()})
                }
                if(action.type == 'removed')
                {
                    return vendorActions.removedVendors({vendor: action.payload.doc.data()})
                }
                return {
                    type: `${action.type}`,
                }
                 
            }),
            catchError(() => {
                return of(vendorActions.vendorError({error: 'error'}))
            })
        )
    });

    createVendor$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(vendorActions.createVendor),
            switchMap(data => {
                console.log('test')
                return this.dfs.collection(`vendors`).doc(data.vendor.id).set(Object.assign({}, data.vendor))
            }),
            map(() => vendorActions.createVendorSuccess()),
            catchError((error) => {
                console.log(error);
                return of(vendorActions.vendorError({error: 'error'}))
            })
        )
    });

    updateVendor$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(vendorActions.updateVendors),
            switchMap(data => {
                const ref = this.dfs.doc<IVendorDataModel>(`vendors/${data.id}`)
                return ref.update(data.changes)
                .then(() => {
                    return vendorActions.updateVendorsSuccess()
                })
                .catch(() => {
                    return vendorActions.vendorError({error: 'error'})
                })
            }),
            catchError((error) => {
                console.log(error);
                return of(vendorActions.vendorError({error: 'error'}))
            })
        )
    });

    deleteVendorQuote$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(vendorActions.deleteVendors),
            switchMap(data => {
                const ref = this.dfs.doc<IVendorDataModel>(`vendors/${data.id}`)
                return ref.delete()
                .then(() => {
                    return vendorActions.deleteVendorsSuccess()
                })
                .catch(() => {
                    return vendorActions.vendorError({error: 'error'})
                })
            }),
            catchError((error) => {
                console.log(error);
                return of(vendorActions.vendorError({error: 'error'}))
            })
        )
    });
}