import { createFeatureSelector, createSelector } from "@ngrx/store";
import { CreateSalesState } from "..";
import { commentAdapter, CommentState } from "../reducers";

export const getCreateSalesState = createFeatureSelector<CreateSalesState>('sales');
export const getCommentsState = createSelector(
    getCreateSalesState,
    (state: CreateSalesState) => state.comments
);

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal
} = commentAdapter.getSelectors(getCommentsState);

export const isCommentsLoading$ = createSelector(getCommentsState, (state: CommentState) => state.loading);
export const isCommentsError$ = createSelector(getCommentsState, (state: CommentState) => state.error);
export const isCommentsNoResult$ = createSelector(getCommentsState, (state: CommentState) => state.noResult);
