import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, map, mergeMap, switchMap } from "rxjs/operators";
import { IVendorQuoteData } from "../../models";
import * as vendorQuoteActions from '../actions/vendor-quotes.action';

@Injectable()
export class VendorQuotesEffects{
    constructor(
        private readonly actions$: Actions,
        private readonly dfs: AngularFirestore
    ){}

    queryVendorQuotesByQuoteIdentifier$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(vendorQuoteActions.queryVendorQuotesByQuoteIdentifier),
            switchMap((action) => {
                // console.log(action);
                let response = this.dfs.collection<IVendorQuoteData>(`quotes/${action.quoteIdentifier}/vendorQuotes`, (ref) => {
                    return ref.orderBy('createdDate');
                }).stateChanges()
                return response;
            }),
            mergeMap(actions => {
                if(actions?.length > 0)
                {
                    return actions;
                }

                return of(vendorQuoteActions.noVendorQuoteResult())
                
            }),
            map(action => {
                // console.log(action.type)
                if(action.type == 'added')
                {
                    return vendorQuoteActions.addedVendorQuotes({vendorQuote: action.payload.doc.data()})
                }
                if(action.type == 'modified')
                {
                    return vendorQuoteActions.modifiedVendorQuotes({vendorQuote: action.payload.doc.data()})
                }
                if(action.type == 'removed')
                {
                    return vendorQuoteActions.removedVendorQuotes({vendorQuote: action.payload.doc.data()})
                }
                return {
                    type: `${action.type}`,
                }
                 
            }),
            catchError(() => {
                return of(vendorQuoteActions.vendorQuoteError({error: 'error'}))
            })
        )
    });

    createVendorQuote$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(vendorQuoteActions.createVendorQuote),
            switchMap(data => {
                return this.dfs.collection(`quotes/${data.quoteIdentifier}/vendorQuotes`).doc(data.vendorQuote.id).set(Object.assign({}, data.vendorQuote))
            }),
            map(() => vendorQuoteActions.createVendorQuoteSuccess()),
            catchError(() => {
                return of(vendorQuoteActions.vendorQuoteError({error: 'error'}))
            })
        )
    });

    updateVendorQuote$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(vendorQuoteActions.updateVendorQuotes),
            switchMap(data => {
                const ref = this.dfs.doc<IVendorQuoteData>(`quotes/${data.quoteIdentifier}/vendorQuotes/${data.id}`)
                return ref.update(data.changes)
                .then(() => {
                    return vendorQuoteActions.updateVendorQuotesSuccess()
                })
                .catch(() => {
                    return vendorQuoteActions.vendorQuoteError({error: 'error'})
                })
            }),
            catchError((error) => {
                console.log(error);
                return of(vendorQuoteActions.vendorQuoteError({error: 'error'}))
            })
        )
    });

    deleteVendorQuote$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(vendorQuoteActions.deleteVendorQuotes),
            switchMap(data => {
                const ref = this.dfs.doc<IVendorQuoteData>(`quotes/${data.quoteIdentifier}/vendorQuotes/${data.id}`)
                return ref.delete()
                .then(() => {
                    return vendorQuoteActions.deleteVendorQuotesSuccess()
                })
                .catch(() => {
                    return vendorQuoteActions.vendorQuoteError({error: 'error'})
                })
            }),
            catchError((error) => {
                console.log(error);
                return of(vendorQuoteActions.vendorQuoteError({error: 'error'}))
            })
        )
    });
}