import { AttachmentsEffects } from './attachments.effects';
import { CommentsEffects } from './comments.effects';
import { QuotesEffects } from './quotes.effects';
import { UsersEffects } from './users.effects';
import { VendorQuotesEffects } from './vendor-quotes.effects';
import { VendorsEffects } from './vendors.effects';

export * from './quotes.effects';
export * from './users.effects';
export * from './comments.effects';
export * from './vendor-quotes.effects';
export * from './attachments.effects';
export * from './vendors.effects';

export const effects: any[] = [QuotesEffects, UsersEffects, CommentsEffects, VendorQuotesEffects, AttachmentsEffects, VendorsEffects];