import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, map, mergeMap, switchMap } from "rxjs/operators";
import { ICommentDataModel } from "../../models";
import * as commentActions from '../actions/comments.action';

@Injectable()
export class CommentsEffects{
    constructor(
        private readonly actions$: Actions,
        private readonly dfs: AngularFirestore
    ){}

    queryCommentsByQuoteIdentifier$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(commentActions.queryCommentsByQuoteIdentifier),
            switchMap((action) => {
                // console.log(action);
                let response = this.dfs.collection<ICommentDataModel>(`quotes/${action.quoteIdentifier}/comments`, (ref) => {
                    return ref.orderBy('createdDate');
                }).stateChanges()
                return response;
            }),
            mergeMap(actions => {
                if(actions?.length > 0)
                {
                    return actions;
                }

                return of(commentActions.noCommentResult())
                
            }),
            map(action => {
                // console.log(action.type)
                if(action.type == 'added')
                {
                    return commentActions.addedComments({comment: action.payload.doc.data()})
                }
                if(action.type == 'modified')
                {
                    return commentActions.modifiedComments({comment: action.payload.doc.data()})
                }
                if(action.type == 'removed')
                {
                    return commentActions.removedComments({comment: action.payload.doc.data()})
                }
                return {
                    type: `${action.type}`,
                }
                 
            }),
            catchError(() => {
                return of(commentActions.commentError({error: 'error'}))
            })
        )
    });

    createComment$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(commentActions.createComment),
            switchMap(data => {
                return this.dfs.collection(`quotes/${data.quoteIdentifier}/comments`).doc(data.comment.id).set(Object.assign({}, data.comment))
            }),
            map(() => commentActions.createCommentSuccess()),
            catchError(() => {
                return of(commentActions.commentError({error: 'error'}))
            })
        )
    });

    updateComment$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(commentActions.updateComments),
            switchMap(data => {
                const ref = this.dfs.doc<ICommentDataModel>(`quotes/${data.quoteIdentifier}/comments/${data.id}`)
                return ref.update(data.changes)
                .then(() => {
                    return commentActions.updateCommentsSuccess()
                })
                .catch(() => {
                    return commentActions.commentError({error: 'error'})
                })
            }),
            catchError((error) => {
                console.log(error);
                return of(commentActions.commentError({error: 'error'}))
            })
        )
    });

    deleteComment$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(commentActions.deleteComments),
            switchMap(data => {
                const ref = this.dfs.doc<ICommentDataModel>(`quotes/${data.quoteIdentifier}/comments/${data.id}`)
                return ref.delete()
                .then(() => {
                    return commentActions.deleteCommentsSuccess()
                })
                .catch(() => {
                    return commentActions.commentError({error: 'error'})
                })
            }),
            catchError((error) => {
                console.log(error);
                return of(commentActions.commentError({error: 'error'}))
            })
        )
    });
}