import { createFeatureSelector, createSelector } from "@ngrx/store";
import { CreateSalesState } from "..";
import { vendorQuoteAdapter, VendorQuoteState } from "../reducers";

export const getCreateSalesState = createFeatureSelector<CreateSalesState>('sales');
export const getVendorQuotesState = createSelector(
    getCreateSalesState,
    (state: CreateSalesState) => state.vendorQuotes
);

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal
} = vendorQuoteAdapter.getSelectors(getVendorQuotesState);

export const isVendorQuotesLoading$ = createSelector(getVendorQuotesState, (state: VendorQuoteState) => state.loading);
export const isVendorQuotesError$ = createSelector(getVendorQuotesState, (state: VendorQuoteState) => state.error);
