import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { AngularFireStorage } from "@angular/fire/storage";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, finalize, map, mergeMap, switchMap } from "rxjs/operators";
import { IFileUploadModel } from "../../models/file-upload.model";
import * as attachmentActions from '../actions/attachments.action';

@Injectable()
export class AttachmentsEffects{
    private basePath = '/quotes';

    constructor(
        private readonly actions$: Actions,
        private readonly dfs: AngularFirestore,
        private readonly storage: AngularFireStorage
    ){}

    queryAttachmentsByQuoteIdentifier$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(attachmentActions.queryAttachmentsByQuoteIdentifier),
            switchMap((action) => {
                // console.log(action);
                let response = this.dfs.collection<IFileUploadModel>(`quotes/${action.quoteIdentifier}/attachments`, (ref) => {
                    return ref.orderBy('createdDate');
                }).stateChanges()
                return response;
            }),
            mergeMap(actions => {
                if(actions?.length > 0)
                {
                    return actions;
                }

                return of(attachmentActions.noAttachmentResult())
                
            }),
            map(action => {
                // console.log(action.type)
                if(action.type == 'added')
                {
                    return attachmentActions.addedAttachments({attachment: action.payload.doc.data()})
                }
                if(action.type == 'modified')
                {
                    return attachmentActions.modifiedAttachments({attachment: action.payload.doc.data()})
                }
                if(action.type == 'removed')
                {
                    return attachmentActions.removedAttachments({attachment: action.payload.doc.data()})
                }
                return {
                    type: `${action.type}`,
                }
                 
            }),
            catchError(() => {
                return of(attachmentActions.attachmentError({error: 'error'}))
            })
        )
    });

    createAttachment$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(attachmentActions.createAttachment),
            switchMap(data => {
                const filePath = `${this.basePath}/${data.quoteIdentifier}/${data.attachment.name}`;
                const storageRef = this.storage.ref(filePath);
                const uploadTask = this.storage.upload(filePath, data.file);

                uploadTask.snapshotChanges().pipe(
                    finalize(() => {
                      storageRef.getDownloadURL().subscribe(downloadURL => {
                        console.log(downloadURL);
                        const newAttachmentObject = Object.assign({url: downloadURL}, data.attachment);
                        console.log(newAttachmentObject);
                        this.saveFileData(data.quoteIdentifier, newAttachmentObject);
                      });
                    })
                  ).subscribe();
                  
                return uploadTask.percentageChanges();
            }),
            map((progress) => {
                console.log(progress);
                return attachmentActions.createAttachmentSuccess({progress})
            }),
            catchError(() => {
                return of(attachmentActions.attachmentError({error: 'error'}))
            })
        )
    });

    deleteAttachment$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(attachmentActions.deleteAttachments),
            switchMap(data => {
                const filePath = `${this.basePath}/${data.quoteIdentifier}`;
                const storageRef = this.storage.ref(filePath);
                storageRef.child(data.name).delete();

                const ref = this.dfs.doc<IFileUploadModel>(`quotes/${data.quoteIdentifier}/attachments/${data.id}`)
                return ref.delete()
                .then(() => {
                    return attachmentActions.deleteAttachmentsSuccess()
                })
                .catch(() => {
                    return attachmentActions.attachmentError({error: 'error'})
                })
            }),
            catchError((error) => {
                console.log(error);
                return of(attachmentActions.attachmentError({error: 'error'}))
            })
        )
    });

    saveFileData(quoteIdentifier: string, attachment: IFileUploadModel)
    {
        this.dfs.collection(`quotes/${quoteIdentifier}/attachments`).doc(attachment.id).set(Object.assign({}, attachment))
    }
}