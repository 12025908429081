import { ThrowStmt } from '@angular/compiler';
import { Component, OnInit, Input } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-box',
  templateUrl: './confirm-box.component.html',
  styleUrls: ['./confirm-box.component.scss']
})
export class ConfirmBoxComponent {

  public confirmMessageHeader: string;
  public confirmMessageContent: string;

  // set confirmMessage(val: string) {
  //   this._confirmMessage = val;
  // }

  constructor(public dialogRef: MatDialogRef<ConfirmBoxComponent>) { }

}
