import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ConfirmBoxComponent, FooterComponent, HeaderComponent } from './shared';
import { SalesModule } from './sales/sales.module';
import { VendorFormModule } from './vendor-form/vendor-form.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSelectModule } from '@angular/material/select';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    ConfirmBoxComponent
  ],
  imports: [
    SalesModule,
    VendorFormModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    BrowserAnimationsModule,
    MatSelectModule
  ],
  providers: [
    {provide : LocationStrategy , useClass: PathLocationStrategy  },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
