import { createEntityAdapter, Dictionary, EntityState } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";
import { addedVendorQuotes, modifiedVendorQuotes, noVendorQuoteResult, queryVendorQuotesByQuoteIdentifier, removedVendorQuotes, vendorQuoteError } from "../actions";
import { IVendorQuoteData } from "../../models";

export const vendorQuoteAdapter = createEntityAdapter<IVendorQuoteData>();

export interface VendorQuoteState extends EntityState<IVendorQuoteData>{
    entites: Dictionary<IVendorQuoteData>,
    error: boolean;
    loading: boolean;
    noResult: boolean;
};

export const initialVendorQuoteState: VendorQuoteState = vendorQuoteAdapter.getInitialState({
    entites: {},
    error: false,
    loading: false,
    noResult: false
});

export const vendorQuotesReducer = createReducer(
    initialVendorQuoteState,

    on(queryVendorQuotesByQuoteIdentifier, (state, {quoteIdentifier}) => {
        return  {
            ...initialVendorQuoteState,
            loading: true
        };
    }),

    on(addedVendorQuotes, (state, { vendorQuote }) => {
        return vendorQuoteAdapter.addOne(vendorQuote, {
            ...state,
            loading: false
        });
    }),

    on(modifiedVendorQuotes, (state, { vendorQuote }) => {
        return vendorQuoteAdapter.updateOne({
            id: vendorQuote.id,
            changes: vendorQuote
        }, {
            ...state,
            loading: false
        })
    }),

    on(removedVendorQuotes, (state, { vendorQuote }) => {
        return vendorQuoteAdapter.removeOne(vendorQuote.id, state);
    }),

    on(vendorQuoteError, (state, { error }) => {
        return  {
            ...state,
            error: 'There was something wrong. Please refresh the page and try again.' ?? error
        };
    }),

    on(noVendorQuoteResult, (state) => {
        return {
            ...state,
            noResult: true
        }
    })
);