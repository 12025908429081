import { createEntityAdapter, Dictionary, EntityState } from "@ngrx/entity";
import { Action, createReducer, on } from "@ngrx/store";
import { IQuotesDataModel, QuoteListFilter } from "../../models";
import { addedQuotes, editQuoteForm, error, lastQuoteInPage, modifiedQuotes, nextPage, noResult, prevPage, queryQuotes, queryQuotesById, queryQuotesByIdSuccess, removedQuotes, resetPage, selectQuote, setFilter, updateQuotes } from "../actions/quotes.action";
import { selectEntities } from "../selectors";

// export function getQuotesReducer(state: QuoteState | undefined, action: Action) {
//     return quotesReducer(state, action);
// }

export const quoteAdapter = createEntityAdapter<IQuotesDataModel>();

export interface QuoteState extends EntityState<IQuotesDataModel> {
    entities: Dictionary<IQuotesDataModel>,
    loading: boolean;
    selectedQuoteId: string;
    error: any;
    filter: QuoteListFilter;
    noResult: boolean;
    editQuoteForm: boolean;
    currentQuote: IQuotesDataModel;
    currentPage: number;
    lastQuoteInCurrentPage: IQuotesDataModel;
    firstQuoteInCurrentPage: IQuotesDataModel;
    nextPage: boolean;
}

export const initialQuoteState: QuoteState = quoteAdapter.getInitialState({
    entities: [],
    loading: false,
    selectedQuoteId: null,
    error: null,
    filter: null,
    noResult: false,
    editQuoteForm: false,
    currentQuote: null,
    currentPage: 1,
    lastQuoteInCurrentPage: null,
    firstQuoteInCurrentPage: null,
    nextPage: true
});

export const quotesReducer = createReducer(

   initialQuoteState,

   on(queryQuotes, (state) => {
        return  {
            ...initialQuoteState,
            filter: state.filter,
            loading: true,
            currentPage: state.currentPage,
            lastQuoteInCurrentPage: state.lastQuoteInCurrentPage,
            firstQuoteInCurrentPage: state.firstQuoteInCurrentPage,
            nextPage: state.nextPage
        };
    }),

    on(queryQuotesById, (state, {id}) => {
        return  {
            ...state,
            loading: true,
            noResult: false
        };
    }),

    on(queryQuotesByIdSuccess, (state, {quote}) => {
        return  {
            ...state,
            loading: false,
            currentQuote: quote
        };
    }),

    on(addedQuotes, (state, { quote }) => {
        return quoteAdapter.addOne(quote, {
            ...state,
            lastQuoteInCurrentPage: quote,
            firstQuoteInCurrentPage: state.loading ? quote : state.firstQuoteInCurrentPage,
            loading: false,
            noResult: false
        });
    }),

    on(modifiedQuotes, (state, { quote }) => {
        return quoteAdapter.updateOne({
            id: quote.id,
            changes: quote
        }, {
            ...state,
            loading: false,
            noResult: false
        })
    }),

    on(removedQuotes, (state, { quote }) => {
        return quoteAdapter.removeOne(quote.id, state);
    }),

    on(selectQuote, (state, { selectedQuoteId }) => {
        return {
            ...state,
            selectedQuoteId,
            editQuoteForm: true,
            noResult: false
        };
    }),

    on(error, (state, { error }) => {
        return  {
            ...state,
            error: 'There was something wrong. Please refresh the page and try again.'
        };
    }),

    on(setFilter, (state, { filter }) => {
        // console.log(filter)
        return  {
            ...state,
            filter,
            currentPage: 1,
            nextPage: true,
            noResult: false,
            editQuoteForm: false
        };
    }),

    on(updateQuotes, (state, { id, changes }) => {
        return {
            ...state,
            error: null,
            noResult: false
        }
    }),

    on(noResult, (state) => {
        return {
            ...state,
            error: null,
            noResult: true,
            loading: false,
            currentPage: 0
        }
    }),

    on(editQuoteForm, (state) => {
        return {
            ...state,
            editQuoteForm: true
        }
    }),

    on(nextPage, (state) => {
        return {
            ...state,
            currentPage: state.currentPage + 1,
            nextPage: true
        }
    }),

    on(prevPage, (state) => {
        return {
            ...state,
            currentPage: state.currentPage - 1,
            nextPage: false
        }
    }),

    on(resetPage, (state) => {
        return {
            ...state,
            currentPage: 1,
            nextPage: true,
            lastQuoteInCurrentPage: null,
            firstQuoteInCurrentPage: null,
            filter: null
        }
    })

    // on(lastQuoteInPage, (state, {quote}) => {
    //     return {
    //         ...state,
    //         lastQuoteInCurrentPage: quote
    //     }
    // })
)