<div class="vendor-link-container" *ngIf="!vendorLink && !(isVendorLoading)">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-4">&nbsp;</div>
            <div class="col-md-4 header-div">
                <h3 class="header-title ">ta<span class="primary-color">ḵ</span>zīn</h3>
                <br />
            </div>

        </div>
        <div class="row">
            <div class="col-md-4 ">&nbsp;</div>
            <div class="col-md-4 form-container ">
                <form [formGroup]="vendorForm" class="vendor-link-form ">
                    <div class="form-group ">
                        <label class="takzin-modal-control-label required-label">
                                Enter Vendor Id
                            </label>
                        <input type="text" value="" id="vendorId" formControlName="vendorId" class="form-control" required>
                        <div *ngIf="vendorId!.invalid && showValidationMessages" class="takzin-validation-msg ">
                            <div *ngIf="vendorId!.errors!.required">
                                Vendor Id is required.
                            </div>
                        </div>
                    </div>
                    <div class="form-group" *ngIf="isVendorLoading">
                        <div class="loading"></div>
                    </div>
                    <br />
                    <button type="button" (click)="onContinue(vendorForm.value)" class="takzin-button-primary takzin-modal-button ">Continue</button>
                </form>
            </div>
        </div>



    </div>
</div>
<br />
<div class="container-fluid" *ngIf="vendorLink && !(isVendorLoading) ">
    <div class="row section-header-row">
        <div class="section-header">Vendor Details</div>
    </div>
    <div class="row form-row">
        <div class="col-md-12">
            <app-vendor-detail [currentVendorData]="vendorData"></app-vendor-detail>
        </div>
    </div>

</div>

<div class="container loading-container" *ngIf="isVendorLoading">
    <div class="row">
        <div class="col-md-5"></div>
        <div class="col-md-5">
            <div class="loading"></div>
        </div>
        <div>
        </div>