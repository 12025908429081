import { createAction, props } from "@ngrx/store";
import { IWarehouseDetailDataModel } from "../../models";

export const queryWarehouseDetails = createAction(
    '[WarehouseDetails] query warehouseDetail'
);

export const queryWarehouseDetailsById = createAction(
    '[WarehouseDetails] query WarehouseDetails by Id',
    props<{id: string}>()
);

export const queryWarehouseDetailsByVendorId = createAction(
    '[WarehouseDetails] query WarehouseDetails by VendorId',
    props<{vendorIdentifier: string}>()
);

export const queryWarehouseDetailsByIdSuccess = createAction(
    '[Users] query warehouseDetail by Id Success',
    props<{warehouseDetail: IWarehouseDetailDataModel}>()
);

export const addedWarehouseDetails = createAction(
    '[WarehouseDetails] added',
    props<{warehouseDetail: IWarehouseDetailDataModel}>()
);

export const createWarehouseDetailByVendorId = createAction(
    '[WarehouseDetails] create warehouseDetail',
    props<{vendorIdentifier: string, warehouseDetail: IWarehouseDetailDataModel}>()
);

export const createWarehouseDetailSuccess = createAction(
    '[WarehouseDetails] create warehouseDetail syccess'
);

export const modifiedWarehouseDetails = createAction(
    '[WarehouseDetails] modified',
    props<{warehouseDetail: IWarehouseDetailDataModel}>()
);

export const updateWarehouseDetails = createAction(
    '[WarehouseDetails] update',
    props<{id:string, vendorIdentifier: string, changes: Partial<IWarehouseDetailDataModel>}>()
);

export const updateWarehouseDetailsSuccess = createAction(
    '[WarehouseDetails] update success'
);

export const deleteWarehouseDetails = createAction(
    '[WarehouseDetails] delete',
    props<{vendorIdentifier: string, id:string}>()
);

export const deleteWarehouseDetailsSuccess = createAction(
    '[WarehouseDetails] delete success'
);

export const removedWarehouseDetails = createAction(
    '[WarehouseDetails] removed',
    props<{warehouseDetail: IWarehouseDetailDataModel}>()
);

export const noWarehouseDetailResult = createAction(
    '[WarehouseDetails] no WarehouseDetail Result'
);

export const warehouseDetailError = createAction(
    '[WarehouseDetails] warehouseDetail Error',
    props<{error: any}>()
);