import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { NavigationEnd, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmBoxComponent } from '../confirm-box/confirm-box.component';

@Component({
  selector: 'takzin-sales-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public hideMenu: boolean;

  constructor(
    private readonly router: Router,
    @Inject(PLATFORM_ID) private readonly platformId: any,
    private firebaseAuth: AngularFireAuth,
    private readonly dialog: MatDialog
  ) { 
    this.router.events.subscribe(val => {
      if (val instanceof NavigationEnd) {
        if(val.url.includes('/login') || val.url.includes('/vendor-form'))
        {
          this.hideMenu = true;
        }
        else
        {
          this.hideMenu = false;
        }
      }});
  }

  ngOnInit(): void {
  }

  goToProfile(){
    if (isPlatformBrowser(this.platformId)) {
    // let loggedInUser = JSON.parse(localStorage.getItem('user'));
    this.router.navigate(['user-profile']);
    // this.router.navigate(['user-profile'], { queryParams: { userIdentifier: loggedInUser.uid }});
    }

  }

  logout(){
    const confirmDialog = this.dialog.open(ConfirmBoxComponent, {
      disableClose: false,
    });
    confirmDialog.componentInstance.confirmMessageHeader = `Logout`;
    confirmDialog.componentInstance.confirmMessageContent = `Are you sure you want to continue?`;
    confirmDialog.afterClosed().subscribe(result => {
      if (result) {
        if (isPlatformBrowser(this.platformId)) {
          localStorage.removeItem('user')
          this.router.navigate(['login']);
      
          this.firebaseAuth.signOut();
          }
      }
    });
  }

}
