import { createEntityAdapter, Dictionary, EntityState } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";
import { IVendorDataModel, VendorListFilter } from "../../models";
import { addedVendors, vendorError, modifiedVendors, noVendorResult, queryVendorsByEmail, removedVendors, queryVendors } from "../actions";

export const vendorAdapter = createEntityAdapter<IVendorDataModel>();

export interface VendorState extends EntityState<IVendorDataModel> {
    entities: Dictionary<IVendorDataModel>;
    loading: boolean;
    error: boolean;
    noResult: boolean;
    filter: VendorListFilter;
    currentPage: number;
    lastQuoteInCurrentPage: IVendorDataModel;
    firstQuoteInCurrentPage: IVendorDataModel;
    nextPage: boolean;
}

export const initialVendorState: VendorState = vendorAdapter.getInitialState({
    entities: {},
    loading: false,
    error: false,
    noResult: false,
    filter: null,
    currentPage: 1,
    lastQuoteInCurrentPage: null,
    firstQuoteInCurrentPage: null,
    nextPage: true
});

export const vendorsReducer = createReducer(
    initialVendorState,
    on(queryVendorsByEmail, (state, {email}) => {
        return  {
            ...initialVendorState,
            loading: true
        };
    }),

    on(queryVendors, (state) => {
        return  {
            ...initialVendorState,
            loading: true
        };
    }),

    on(addedVendors, (state, { vendor }) => {
        return vendorAdapter.addOne(vendor, {
            ...state,
            loading: false
        });
    }),

    on(modifiedVendors, (state, { vendor }) => {
        return vendorAdapter.updateOne({
            id: vendor.id,
            changes: vendor
        }, {
            ...state,
            loading: false
        })
    }),

    on(removedVendors, (state, { vendor }) => {
        return vendorAdapter.removeOne(vendor.id, state);
    }),

    on(vendorError, (state, { error }) => {
        return  {
            ...state,
            error: 'There was something wrong. Please refresh the page and try again.' ?? error
        };
    }),

    on(noVendorResult, (state) => {
        return {
            ...state,
            noResult: true,
            loading: false
        }
    }),
);