import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VendorDetailComponent, VendorFormComponent, WarehouseDetailComponent } from './components';
import { VendorFormRoutingModule } from './vendor-form-routing.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { environment } from 'src/environments/environment';
import * as fromStore from './store';
import { VendorsStoreFacade } from './store/facades';
import { MatSnackBarModule } from '@angular/material/snack-bar';

@NgModule({
  declarations: [
    VendorFormComponent,
    VendorDetailComponent,
    WarehouseDetailComponent
  ],
  imports: [
    CommonModule,
    VendorFormRoutingModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatSnackBarModule,
    StoreModule.forFeature('vendorData', fromStore.createSalesReducers),
    EffectsModule.forRoot(),
    EffectsModule.forFeature(fromStore.effects),
    AngularFireModule.initializeApp(environment.firebaseConfig, 'takzin'),
    AngularFirestoreModule, // Only required for database features
    AngularFireAuthModule, // Only required for auth features,
    AngularFireStorageModule // Only required for storage features
  ],
  providers: [
    VendorsStoreFacade
  ]
})
export class VendorFormModule { }
