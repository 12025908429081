import { createAction, props } from "@ngrx/store";
import { IFileUploadModel } from "../../models/file-upload.model";

export const queryAttachmentsByQuoteIdentifier = createAction(
    '[Attachments] query Attachments by Quote Identifier',
    props<{quoteIdentifier: string}>()
);

export const addedAttachments = createAction(
    '[Attachments] added',
    props<{attachment: IFileUploadModel}>()
);

export const createAttachment = createAction(
    '[Attachments] create Attachment',
    props<{quoteIdentifier: string, attachment: IFileUploadModel, file: File}>()
);

export const createAttachmentSuccess = createAction(
    '[Attachments] create Attachment success',
    props<{progress: number}>()
);

export const modifiedAttachments = createAction(
    '[Attachments] modified',
    props<{attachment: IFileUploadModel}>()
);

export const updateAttachments = createAction(
    '[Attachments] update',
    props<{quoteIdentifier: string, id:string, changes: Partial<IFileUploadModel>}>()
);

export const updateAttachmentsSuccess = createAction(
    '[Attachments] update success'
);

export const deleteAttachments = createAction(
    '[Attachments] delete',
    props<{quoteIdentifier: string, id:string, name:string}>()
);

export const deleteAttachmentsSuccess = createAction(
    '[Attachments] delete success'
);

export const removedAttachments = createAction(
    '[Attachments] removed',
    props<{attachment: IFileUploadModel}>()
);

export const noAttachmentResult = createAction(
    '[Attachments] no Attachment Result'
);

export const attachmentError = createAction(
    '[Attachments] attachment Error',
    props<{error: any}>()
);