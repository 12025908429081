import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent, QuotesDetailsComponent, UserProfileComponent, VendorLinkComponent, VendorListComponent } from './components';
import { SalesHomeContainer } from './container';
import { AuthGuard } from './guards';

const routes: Routes = [
  { path: '', component: SalesHomeContainer, pathMatch: 'full', canActivate: [AuthGuard] },
  { path: 'quote-details', component: QuotesDetailsComponent, pathMatch: 'full', canActivate: [AuthGuard] },
  { path: 'user-profile', component: UserProfileComponent, pathMatch: 'full', canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent, pathMatch: 'full' },
  { path: 'vendor-link', component: VendorLinkComponent, pathMatch: 'full' },
  { path: 'vendor-list', component: VendorListComponent, pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SalesRoutingModule { }
