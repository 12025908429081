import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { IFileUploadModel } from '../../models/file-upload.model';
import { AttachmentsStoreFacade } from '../../store/facades/attachments.store-facade';
import { UUID } from 'angular2-uuid';
import firebase from 'firebase/app';
import Timestamp = firebase.firestore.Timestamp;
import {formatDate} from '@angular/common';
import { IUserModel } from '../../models';
import { UsersStoreFacade } from '../../store/facades/users.store-facade';
import { ConfirmBoxComponent } from 'src/app/shared/components';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-quote-attachments',
  templateUrl: './quote-attachments.component.html',
  styleUrls: ['./quote-attachments.component.scss']
})
export class QuoteAttachmentsComponent implements OnInit {
  public attachments$: Observable<IFileUploadModel[]>;
  public progress: number;
  public currentFileUpload: boolean;
  public isUserLoading$: Observable<boolean>;
  public isAttachmentLoading$: Observable<boolean>;
  public displayedColumns: string[] = ['fileName', 'createdBy', 'createdDate', 'action'];
  public dateTimeFormatOptions = { month: "long", hour: "numeric", minute: "numeric", day: "numeric", year: "numeric" } as const;
  public locale = 'en-US';
  public allUsers$: Observable<IUserModel[]>;
  public noResult$: Observable<boolean>;
  public errorMessage: string;
  
  @Input()
  quoteIdentifier: string;

  constructor(
    private readonly attachmentFacade: AttachmentsStoreFacade,
    private readonly userFacade: UsersStoreFacade,
    private readonly dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.errorMessage = null;
    this.attachmentFacade.queryAttachmentsByQuoteIdentifier(this.quoteIdentifier);
    this.attachments$ = this.attachmentFacade.selectAllAttachments$;
    this.noResult$ = this.attachmentFacade.noAttachmentsFound$;
    this.attachmentFacade.uploadProgress$.subscribe(
      percentage => {
        this.progress = Math.round(percentage);
        if(this.progress == 100)
        {
          this.currentFileUpload = false;
          // this.attachmentFacade.queryAttachmentsByQuoteIdentifier(this.quoteIdentifier);
        }
      });
      // this.userFacade.queryUsers();
      this.isUserLoading$ = this.userFacade.isUserLoading$;
      this.allUsers$ = this.userFacade.selectAllUsers$;
      this.isAttachmentLoading$ = this.attachmentFacade.attachmentLoading$;
  }

  selectFile()
  {

  }

  onFileChange(event) {
    this.errorMessage = null;
    const fileList: FileList = event.target.files;
  
    let loggedInUser = JSON.parse(localStorage.getItem('user'));
    for(var i =0; i < fileList.length; i++)
    {
      let file = fileList[i];
      if(file.size/Math.pow(1024,2) > 10)
      {
        this.errorMessage = 'The file that you are trying to upload exceeds the limit of 10MB';
        // alert('File Size Exceeded');
        return;
      }
      var allowedExtensions = ["xls","xlsx", "pdf", "doc", "docx", "png", "jpg","jpeg"];
      var extn = file.name.split(".").pop();
      if(allowedExtensions.indexOf(extn.toLowerCase()) < 0 ) {
        this.errorMessage = ' The attached file type is not supported. Please try using .xls, .xlsx, .pdf, .doc, .docx, .png, .jpg, .jpeg file types.';
        // alert('File Type invalid');
        return;
      }
      let newAttachment: IFileUploadModel = {
        id: UUID.UUID(),
        name: file.name,
        createdBy: loggedInUser.uid,
        createdDate: firebase.firestore.Timestamp.fromDate(new Date(new Date().toUTCString().substr(0, 25)))
      };
      this.attachmentFacade.createAttachment(this.quoteIdentifier, newAttachment, file);
      this.currentFileUpload = true;
      this.progress = 0;
    }
  }

  OnFileClick(event){
    console.log('OnFileClick')
    event.target.value = '';
  }

  onCloseClick() {
    console.log('onCloseClick')
    // if(this.file) {
    // this.file= null;
    // }
  }

  getFormattedDate(timestamp: Timestamp){
    const date =  new Date(formatDate(timestamp.toDate(), 'medium', this.locale));
    date.setHours(date.getHours() + 4);
    return date.toLocaleString(this.locale, this.dateTimeFormatOptions);
  }
  
  public getUserDisplayName(userId: string, users: IUserModel[]){
    const user = users.filter((ele) => ele.id == userId)[0];
    return user?.displayFullName;
  }

  deleteAttachment(element)
  {
    const confirmDialog = this.dialog.open(ConfirmBoxComponent, {
      disableClose: false,
    });
    confirmDialog.componentInstance.confirmMessageHeader = `Delete ${element.name}`;
    confirmDialog.componentInstance.confirmMessageContent = `Are you sure you want to continue?`;
    confirmDialog.afterClosed().subscribe(result => {
      if (result) {
        this.attachmentFacade.deleteAttachments(this.quoteIdentifier, element.id, element.name);
      }
    });
  }
}
