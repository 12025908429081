import { createFeatureSelector, createSelector } from "@ngrx/store";
import { CreateSalesState } from "../reducers";
import { quoteAdapter, QuoteState } from "../reducers/quotes.reducer";

export const getCreateSalesState = createFeatureSelector<CreateSalesState>('sales');
export const getQuotesState = createSelector(
    getCreateSalesState,
    (state: CreateSalesState) => state.quotes
);


export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal
} = quoteAdapter.getSelectors(getQuotesState);

export const isLoadingQuotes$ = createSelector(getQuotesState, (state: QuoteState) => state.loading);
export const selectedQuoteId$ = createSelector(getQuotesState, (state: QuoteState) => state.selectedQuoteId);
export const error$ = createSelector(getQuotesState, (state: QuoteState) => state.error);
export const getFilter$ = createSelector(getQuotesState, (state: QuoteState) => state.filter);
export const noResult$ = createSelector(getQuotesState, (state: QuoteState) => state.noResult);
export const getEditQuoteForm$ = createSelector(getQuotesState, (state: QuoteState) => state.editQuoteForm);
export const getCurrentQuote$ = createSelector(getQuotesState, (state: QuoteState) => state.currentQuote);
export const getCurrentPage$ = createSelector(getQuotesState, (state: QuoteState) => state.currentPage);
export const getlastQuoteInCurrentPage$ = createSelector(getQuotesState, (state: QuoteState) => state.lastQuoteInCurrentPage);
export const getfirstQuoteInCurrentPage$ = createSelector(getQuotesState, (state: QuoteState) => state.firstQuoteInCurrentPage);
export const getNextPage$ = createSelector(getQuotesState, (state: QuoteState) => state.nextPage);