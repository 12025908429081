import { createFeatureSelector, createSelector } from "@ngrx/store";
import { CreateSalesState } from "..";
import { vendorAdapter, VendorState } from "../reducers";

export const getCreateSalesState = createFeatureSelector<CreateSalesState>('sales');
export const getVendorsState = createSelector(
    getCreateSalesState,
    (state: CreateSalesState) => state.vendors
);

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal
} = vendorAdapter.getSelectors(getVendorsState);

export const isVendorsLoading$ = createSelector(getVendorsState, (state: VendorState) => state.loading);
export const isVendorsError$ = createSelector(getVendorsState, (state: VendorState) => state.error);
export const isVendorsNoResult$ = createSelector(getVendorsState, (state: VendorState) => state.noResult);
export const getCurrentPage$ = createSelector(getVendorsState, (state: VendorState) => state.currentPage);
export const getlastQuoteInCurrentPage$ = createSelector(getVendorsState, (state: VendorState) => state.lastQuoteInCurrentPage);
export const getfirstQuoteInCurrentPage$ = createSelector(getVendorsState, (state: VendorState) => state.firstQuoteInCurrentPage);
export const getNextPage$ = createSelector(getVendorsState, (state: VendorState) => state.nextPage);
export const getFilter$ = createSelector(getVendorsState, (state: VendorState) => state.filter);
