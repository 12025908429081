<div class="container-fluid quote-details-container">
    <div class="row" *ngIf="!(isUserLoading$ | async) && !(isQuoteLoading$ |async) ">
        <div class="col-md-12">
            <mat-tab-group mat-align-tabs="start" *ngIf="!(isUserLoading$ | async) && !(isQuoteLoading$ |async)">
                <mat-tab label="Quote View">
                    <br />
                    <form class="quote-details-form" [formGroup]="quoteDetailsForm" (ngSubmit)="onSubmit(quoteDetailsForm.value)">
                        <div class="container-fluid">
                            <!-- Quote Details -->
                            <div class="row section-header-row">
                                <div class="section-header">Quote Details</div>
                            </div>
                            <div class="row form-row">

                                <div class="col-md-1 form-label-div">
                                    <label>
                                        <b>Quote Id:</b>
                                    </label>
                                </div>
                                <div class="col-md-3 form-label-div">
                                    <label>
                                        {{ quoteId }}
                                    </label>
                                </div>
                                <div class="col-md-1 form-label-div">
                                    <label>
                                        <b>Date of Enquiry:</b>
                                    </label>
                                </div>
                                <div class="col-md-3 form-label-div">
                                    <label>
                                        {{ getFormattedDate(this.quoteDetail?.createdDate) }}
                                    </label>
                                </div>
                                <div class="col-md-1 form-label-div">
                                    <label>
                                        <b>Assigned To:</b>
                                    </label>
                                </div>
                                <div class="col-md-3 form-group">
                                    <select id="assignedTo" type="text" class="form-control" formControlName="assignedTo">
                                        <option value="Select your option" selected>Select your option</option>
                                        <option *ngFor="let user of filterUserWithRole(allUsers$ | async, 'sales')" value="{{user.id}}">{{user.displayFullName}}</option>
                                    </select>
                                </div>

                                <div class="col-md-1 form-label-div">
                                    <label class="required-label">
                                        <b>Status:</b>
                                    </label>
                                </div>
                                <div class="col-md-3 form-group">
                                    <select autofocus id="status" type="text" class="form-control modal-select" formControlName="status" required>
                                        <option value="Select your option" disabled selected>Select your option</option>
                                        <option value="new">New</option>
                                        <option value="open">Open</option>
                                        <option value="inprogress">In Progress</option>
                                        <option value="awaitingresponse">Awaiting Response</option>
                                        <option value="closed">Closed</option>
                                    </select>
                                    <div *ngIf="status!.invalid && showValidationMessages && status!.errors!.required" class="takzin-validation-msg">
                                        <div *ngIf="status!.errors!.required">
                                            Status is required.
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-1 form-label-div">
                                    <label class="required-label">
                                        <b>Quote Type:</b>
                                    </label>
                                </div>
                                <div class="col-md-3 form-group" *ngIf="newQuoteForm">
                                    <select autofocus id="quoteTypeControl" type="text" class="form-control" (change)="selectQuoteType()" formControlName="quoteTypeControl" required>
                                        <option value="Select your option" disabled selected>Select your option</option>
                                        <option value="storage">Storage</option>
                                        <option value="transport">Transport</option>
                                    </select>
                                    <div *ngIf="quoteTypeControl!.invalid && showValidationMessages" class="takzin-validation-msg">
                                        <div *ngIf="quoteTypeControl!.errors!">
                                            Quote Type is required.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 form-label-div" *ngIf="!newQuoteForm">
                                    <label>
                                        {{ quoteType == 'transport' ? 'Transport' : 'Storage' }}
                                    </label>
                                </div>
                                <div class="col-md-1 form-label-div" *ngIf="newQuoteForm">
                                    <label class="required-label">
                                        <b>Quote Source:</b>
                                    </label>
                                </div>
                                <div class="col-md-3 form-group" *ngIf="newQuoteForm">
                                    <select autofocus id="quoteSource" type="text" class="form-control" (change)="selectQuoteType()" formControlName="quoteSource" required>
                                        <option value="Select your option" disabled selected>Select your option</option>
                                        <option value="website">Website</option>
                                        <option value="whatsapp">Whatsapp</option>
                                        <option value="contact">Contact Us</option>
                                    </select>
                                    <div *ngIf="quoteSource!.invalid && showValidationMessages" class="takzin-validation-msg">
                                        <div *ngIf="quoteSource!.errors!">
                                            Quote Type is required.
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <!-- Customer Details -->
                            <br /><br />
                            <div class="row section-header-row">
                                <div class="section-header">Customer Details</div>
                            </div>
                            <div class="row form-row">
                                <div class="col-md-1 form-label-div">
                                    <label class="required-label">
                                        <b>Name:</b>
                                    </label>
                                </div>
                                <div class="col-md-3 form-group">
                                    <input type="text" value="{{this.quoteDetail?.name}}" id="name" formControlName="name" class="form-control" required>
                                    <div *ngIf="name!.invalid && showValidationMessages && name!.errors!.required" class="takzin-validation-msg">
                                        <div *ngIf="name!.errors!.required">
                                            Name is required.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-1 form-label-div">
                                    <label class="required-label">
                                        <b>Email Id:</b>
                                    </label>
                                </div>
                                <div class="col-md-3 form-group">
                                    <input type="text" value="{{this.quoteDetail?.email}}" id="email" formControlName="email" class="form-control" required>
                                    <div *ngIf="email!.invalid && showValidationMessages" class="takzin-validation-msg">
                                        <div *ngIf="email!.errors!.required">
                                            Email is required.
                                        </div>
                                        <div *ngIf="email!.errors!.pattern">
                                            Email is invalid.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-1 form-label-div">
                                    <label class="required-label">
                                        <b>Company Name:</b>
                                    </label>
                                </div>
                                <div class="col-md-3 form-group">
                                    <input type="text" value="{{this.quoteDetail?.companyName}}" id="companyName" formControlName="companyName" class="form-control" required>
                                    <div *ngIf="companyName!.invalid && showValidationMessages && companyName!.errors!.required" class="takzin-validation-msg">
                                        <div *ngIf="companyName!.errors!.required">
                                            Company Name is required.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-1 form-label-div">
                                    <label>
                                            <b>Contact Number:</b>
                                        </label>
                                </div>
                                <div class="col-md-3 form-group">
                                    <input type="text" value="{{this.quoteDetail?.contactNumber}}" id="contactNumber" formControlName="contactNumber" class="form-control">
                                </div>
                            </div>

                            <!-- Request Details -->
                            <br *ngIf="quoteType" /><br *ngIf="quoteType" />
                            <div class="row section-header-row" *ngIf="quoteType">
                                <div class="section-header">Request Details</div>
                            </div>
                            <div class="row form-row" *ngIf="quoteType == 'storage'">
                                <div class="col-md-1 form-label-div">
                                    <label class="required-label">
                                        <b>Emirate:</b>
                                    </label>
                                </div>
                                <div class="col-md-3 form-group">
                                    <select autofocus id="emirate" type="text" class="form-control modal-select" formControlName="emirate">
                                        <option value="Select your option" disabled selected>Select your option</option>
                                        <option value="Dubai">Dubai</option>
                                        <option value="Umm Al Quwain" >Umm Al Quwain</option>
                                        <option value="Sharjah" >Sharjah</option>
                                        <option value="Abu Dhabi">Abu Dhabi</option>
                                        <option value="Ajman">Ajman</option>
                                        <option value="Fujairah">Fujairah</option>
                                        <option value="Ras Al Khaimah">Ras Al Khaimah</option>
                                    </select>
                                    <div *ngIf="emirate!.invalid && showValidationMessages && emirate!.errors!.required" class="takzin-validation-msg">
                                        <div *ngIf="emirate!.errors!.required">
                                            Emirate is required.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-1 form-label-div">
                                    <label class="required-label">
                                        <b>Region:</b>
                                    </label>
                                </div>
                                <div class="col-md-3 form-group">
                                    <input type="text" value="{{this.quoteDetail?.area}}" id="region" formControlName="region" class="form-control">
                                    <div *ngIf="region!.invalid && showValidationMessages && region!.errors!.required" class="takzin-validation-msg">
                                        <div *ngIf="region!.errors!.required">
                                            Region is required.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-1 form-label-div">
                                    <label class="required-label">
                                        <b>Item Type:</b>
                                    </label>
                                </div>
                                <div class="col-md-3 form-group">
                                    <input type="text" value="{{this.quoteDetail?.item}}" id="item" formControlName="item" class="form-control">
                                    <div *ngIf="item!.invalid && showValidationMessages && item!.errors!.required" class="takzin-validation-msg">
                                        <div *ngIf="item!.errors!.required">
                                            Item Type is required.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-1 form-label-div">
                                    <label class="required-label">
                                        <b>Storage Type:</b>
                                    </label>
                                </div>
                                <div class="col-md-3 form-group">
                                    <select autofocus id="storage" type="text" class="form-control modal-select" formControlName="storage">
                                        <option value="Select your option" disabled selected>Select your option</option>
                                        <option value="Ambient (20°C to 24°C)">Ambient (20°C to 24°C)</option>
                                        <option value="Dry (26°C & Above)" >Dry (26°C & Above)</option>
                                        <option value="Freezer (- 17°C to - 20°C)" >Freezer (- 17°C to - 20°C)</option>
                                        <option value="Chiller (1°C to 5°C)">Chiller (1°C to 5°C)</option>
                                        <option value="Bulk Ambient (20°C to 24°C)">Bulk Ambient (20°C to 24°C)</option>
                                        <option value="Bulk Dry (26°C & Above)">Bulk Dry (26°C & Above)</option>
                                        <option value="Bulk Freezer (- 17°C to - 20°C)">Bulk Freezer (- 17°C to - 20°C)</option>
                                        <option value="Bulk Chiller (1°C to 5°C)">Bulk Chiller (1°C to 5°C)</option>
                                        <option value="Open Yard/Space">Open Yard/Space</option>
                                    </select>
                                    <div *ngIf="storage!.invalid && showValidationMessages && storage!.errors!.required" class="takzin-validation-msg">
                                        <div *ngIf="storage!.errors!.required">
                                            Storage is required.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-1 form-label-div">
                                    <label class="required-label">
                                        <b>Handling:</b>
                                    </label>
                                </div>
                                <div class="col-md-3 form-group">
                                    <select autofocus id="handling" type="text" class="form-control modal-select" formControlName="handling">
                                        <option value="Select your option" disabled selected>Select your option</option>
                                        <option value="cbm">CBM</option>
                                        <option value="pallet">Pallet</option>
                                    </select>
                                    <div *ngIf="handling!.invalid && showValidationMessages && handling!.errors!.required" class="takzin-validation-msg">
                                        <div *ngIf="handling!.errors!.required">
                                            Handling is required.
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-1 form-label-div">
                                    <label>
                                        <b>Land:</b>
                                    </label>
                                </div>
                                <div class="col-md-3 form-group">
                                    <select autofocus id="land" type="text" class="form-control" formControlName="land">
                                        <option value="Select your option" selected>Select your option</option>
                                        <option value="Main Land">Main Land</option>
                                        <option value="Freezone">Freezone</option>
                                    </select>
                                </div>

                                <div class="col-md-1 form-label-div">
                                    <label>
                                        <b>Value Added:</b>
                                    </label>
                                </div>
                                <div class="col-md-3 form-group">
                                    <select autofocus id="valueAdded" type="text" class="form-control" formControlName="valueAdded">
                                        <option value="Select your option" selected>Select your option</option>
                                        <option value="Packing">Packing</option>
                                        <option value="Unpacking">Unpacking</option>
                                    </select>
                                </div>

                                <div class="col-md-1 form-label-div">
                                    <label>
                                        <b>Duration:</b>
                                    </label>
                                </div>
                                <div class="col-md-3 form-group">
                                    <select autofocus id="duration" type="text" class="form-control" formControlName="duration">
                            <option value="Select your option" selected>Select your option</option>
                            <option value="Short Term (1 to 6 Months)">Short Term (1 to 6 Months)</option>
                            <option value="Long Term (6 Months & More)">Long Term (6 Months & More)</option>
                        </select>
                                    <!-- <input type="text" value="{{this.quoteDetail?.duration}}" id="duration" formControlName="duration" class="form-control"> -->
                                </div>
                                <div class="col-md-1 form-label-div">
                                    <label>
                            <b>Message from Customer:</b>
                        </label>
                                </div>
                                <div class="col-md-3 form-group">
                                    <textarea type="text" value="{{this.quoteDetail?.userMessage}}" id="userMessage" formControlName="userMessage" class="form-control" row="5"></textarea>
                                </div>
                                <div class="col-md-1 form-label-div">
                                    <label>
                                        <b>From:</b>
                                    </label>
                                </div>
                                <div class="col-md-3 form-group">
                                    <input matInput [matDatepicker]="frompicker" (focus)="frompicker.open()" placeholder="from" formControlName="from" class="form-control date-picker" [min]="minDate" [max]="to!.value ? to!.value : ''" readonly>
                                    <mat-datepicker-toggle matSuffix [for]="frompicker" class="date-picker-icon"></mat-datepicker-toggle>
                                    <mat-datepicker-toggle matSuffix (click)="clearDate('from')">
                                        <mat-icon matDatepickerToggleIcon>clear</mat-icon>
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #frompicker></mat-datepicker>
                                    <!-- <input type="text" value="{{this.quoteDetail?.from}}" id="from" formControlName="from" class="form-control"> -->
                                    <div *ngIf="from!.invalid && showValidationMessages && from!.errors" class="takzin-validation-msg">
                                        <div *ngIf="from!.errors">
                                            Date is invalid.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-1 form-label-div">
                                    <label>
                                        <b>To:</b>
                                    </label>
                                </div>
                                <div class="col-md-3 form-group">
                                    <input matInput [matDatepicker]="topicker" (focus)="topicker.open()" placeholder="to" formControlName="to" class="form-control date-picker" [min]="from!.value ? from!.value : minDate" readonly>
                                    <mat-datepicker-toggle matSuffix [for]="topicker" class="date-picker-icon"></mat-datepicker-toggle>
                                    <mat-datepicker-toggle matSuffix (click)="clearDate('to')">
                                        <mat-icon matDatepickerToggleIcon>clear</mat-icon>
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #topicker></mat-datepicker>
                                    <!-- <input type="text" value="{{this.quoteDetail?.to}}" id="to" formControlName="to" class="form-control"> -->
                                </div>
                                <!-- <div class="col-md-12 form-label-div"></div> -->

                            </div>
                            <div class="row form-row" *ngIf="quoteType == 'transport'">
                                <div class="col-md-1 form-label-div">
                                    <label class="required-label">
                            <b>Vehicle Type:</b>
                        </label>
                                </div>
                                <div class="col-md-3 form-group">
                                    <select autofocus id="vehicleType" type="text" class="form-control" formControlName="vehicleType">
                            <option value="Select your option" disabled selected>Select your option</option>
                            <option value="OneTonPickUp">1 ton</option>
                            <option value="ThreeTonPickUp">3 ton</option>
                            <option value="SevenTonPickUp">7 ton</option>
                            <option value="TenTonPickUp">10 ton</option>
                            <option value="ContainerTrailerForty">Container Flatbed 40"</option>
                            <option value="CurtainTrailerForty">Curtain Trailer 40"</option>
                            <option value="FlatBedTrailerForty">Flatbed Trailer 40"</option>
                        </select>
                                    <div *ngIf="vehicleType!.invalid && showValidationMessages && vehicleType!.errors!.required" class="takzin-validation-msg">
                                        <div *ngIf="vehicleType!.errors!.required">
                                            Vehicle Type is required.
                                        </div>
                                    </div>
                                    <!-- <input type="text" value="{{this.quoteDetail?.vehicleType}}" id="vehicleType" formControlName="vehicleType" class="form-control" required> -->
                                </div>
                                <div class="col-md-1 form-label-div">
                                    <label class="required-label">
                            <b>Pickup Location:</b>
                        </label>
                                </div>
                                <div class="col-md-3 form-group">
                                    <select autofocus id="pickupLocation" type="text" class="form-control" formControlName="pickupLocation">
                            <option value="Select your option" disabled selected>Select your option</option>
                            <option value="Dubai">Dubai</option>
                            <option value="Umm Al Quwain" >Umm Al Quwain</option>
                            <option value="Sharjah" >Sharjah</option>
                            <option value="Abu Dhabi">Abu Dhabi</option>
                            <option value="Ajman">Ajman</option>
                            <option value="Fujairah">Fujairah</option>
                            <option value="Ras Al Khaimah">Ras Al Khaimah</option>
                            <option value="Ras Al Khaimah">Al Ain</option>
                        </select>
                                    <div *ngIf="pickupLocation!.invalid && showValidationMessages && pickupLocation!.errors!.required" class="takzin-validation-msg">
                                        <div *ngIf="pickupLocation!.errors!.required">
                                            Pick up Location is required.
                                        </div>
                                    </div>
                                    <!-- <input type="text" value="{{this.quoteDetail?.pickupLocation}}" id="pickupLocation" formControlName="pickupLocation" class="form-control" required> -->
                                </div>
                                <div class="col-md-1 form-label-div">
                                    <label class="required-label">
                            <b>Drop Location:</b>
                        </label>
                                </div>
                                <div class="col-md-3 form-group">
                                    <select autofocus id="dropLocation" type="text" class="form-control" formControlName="dropLocation">
                            <option value="Select your option" disabled selected>Select your option</option>
                            <option value="Dubai">Dubai</option>
                            <option value="Umm Al Quwain" >Umm Al Quwain</option>
                            <option value="Sharjah" >Sharjah</option>
                            <option value="Abu Dhabi">Abu Dhabi</option>
                            <option value="Ajman">Ajman</option>
                            <option value="Fujairah">Fujairah</option>
                            <option value="Ras Al Khaimah">Ras Al Khaimah</option>
                            <option value="Ras Al Khaimah">Al Ain</option>
                        </select>
                                    <div *ngIf="dropLocation!.invalid && showValidationMessages && dropLocation!.errors!.required" class="takzin-validation-msg">
                                        <div *ngIf="dropLocation!.errors!.required">
                                            Drop Location is required.
                                        </div>
                                    </div>
                                    <!-- <input type="text" value="{{this.quoteDetail?.dropLocation}}" id="dropLocation" formControlName="dropLocation" class="form-control" required> -->
                                </div>
                                <div class="col-md-1 form-label-div">
                                    <label>
                            <b>Pickup Date:</b>
                        </label>
                                </div>
                                <div class="col-md-3 form-group">
                                    <input matInput [matDatepicker]="pickupDate" (focus)="pickupDate.open()" formControlName="pickupDate" class="form-control date-picker" [min]="minDate" [max]="" readonly>
                                    <mat-datepicker-toggle matSuffix [for]="pickupDate" class="date-picker-icon"></mat-datepicker-toggle>
                                    <mat-datepicker-toggle matSuffix (click)="clearDate('pickup')">
                                        <mat-icon matDatepickerToggleIcon>clear</mat-icon>
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #pickupDate></mat-datepicker>
                                    <!-- <input type="text" value="{{this.quoteDetail?.pickupDate}}" id="pickupDate" formControlName="pickupDate" class="form-control"> -->
                                </div>
                                <div class="col-md-1 form-label-div">
                                    <label>
                            <b>Pickup Time:</b>
                        </label>
                                </div>
                                <div class="col-md-3 form-group">
                                    <input type="text" value="{{this.quoteDetail?.pickupTime}}" id="pickupTime" formControlName="pickupTime" class="form-control">
                                </div>
                                <div class="col-md-12 form-label-div"></div>
                                <div class="col-md-1 form-label-div">
                                    <label>
                            <b>Message from Customer:</b>
                        </label>
                                </div>
                                <div class="col-md-6 form-group">
                                    <textarea type="text" value="{{this.quoteDetail?.userMessage}}" id="userMessage" formControlName="userMessage" class="form-control"></textarea>
                                </div>
                            </div>

                            <!-- Additional Details -->
                            <br *ngIf="quoteType" /><br *ngIf="quoteType" />
                            <div class="row section-header-row" *ngIf="quoteType">
                                <div class="section-header">Additional Details</div>
                            </div>
                            <div class="row form-row" *ngIf="quoteType">
                                <div class="col-md-1 form-label-div">
                                    <label>
                                        <b>Volume:</b>
                                    </label>
                                </div>
                                <div class="col-md-3 form-group">
                                    <input type="text" value="{{this.quoteDetail?.volume}}" id="volume" formControlName="volume" class="form-control">
                                </div>
                                <div class="col-md-1 form-label-div">
                                    <label>
                                        <b>Estimated Time of Arrival:</b>
                                    </label>
                                </div>
                                <div class="col-md-3 form-group">
                                    <input type="text" value="{{this.quoteDetail?.eta}}" id="eta" formControlName="eta" class="form-control">
                                </div>
                                <div class="col-md-1 form-label-div">
                                    <label>
                                        <b>No of Pallets or Cartons:</b>
                                    </label>
                                </div>
                                <div class="col-md-3 form-group">
                                    <input type="text" value="{{this.quoteDetail?.noOfPalletsOrCartons}}" id="noOfPalletsOrCartons" formControlName="noOfPalletsOrCartons" class="form-control">
                                </div>
                                <div class="col-md-1 form-label-div">
                                    <label>
                                        <b>Other Details:</b>
                                    </label>
                                </div>
                                <div class="col-md-11 form-group">
                                    <textarea row="4" type="text" value="{{this.quoteDetail?.additionalDetails}}" id="additionalDetails" formControlName="additionalDetails" class="form-control"></textarea>
                                </div>
                            </div>




                        </div>

                        <br />
                        <div class="form-button-container">
                            <button type="submit" class="takzin-button-primary takzin-modal-button" id="search-rates">Submit</button>
                            <!-- <button type="button" (click)="onDelete(this.quoteDetail)" class="takzin-button-primary takzin-modal-button" id="search-rates" *ngIf="isAdminUser">Delete</button> -->
                            <button (click)="onCancelClick()" type="button" class="takzin-button-secondary takzin-modal-button">Cancel</button>
                        </div>
                    </form>


                </mat-tab>
                <mat-tab label="Vendor Details View" *ngIf="currentQuoteIdentifier">
                    <div class="container-fluid">
                        <!-- Vendor Details-->
                        <br *ngIf="currentQuoteIdentifier" />
                        <div class="row section-header-row" *ngIf="currentQuoteIdentifier">
                            <div class="section-header">Vendor Details</div>
                        </div>
                        <div class="row form-row" *ngIf="currentQuoteIdentifier">
                            <div class="col-md-12">
                                <app-vendor-detail-section [quoteIdentifier]="currentQuoteIdentifier"></app-vendor-detail-section>
                            </div>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab label="Comments" *ngIf="currentQuoteIdentifier">
                    <div class="container-fluid">
                        <!--Comments -->
                        <br *ngIf="currentQuoteIdentifier" />
                        <div class="row section-header-row" *ngIf="currentQuoteIdentifier">
                            <div class="section-header">Comments</div>
                        </div>
                        <div class="row form-comment-row" *ngIf="currentQuoteIdentifier">
                            <div class="col-md-8">
                                <app-comment-section [quoteIdentifier]="currentQuoteIdentifier"></app-comment-section>
                            </div>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab label="Attachments" *ngIf="currentQuoteIdentifier">
                    <div class="container-fluid">
                        <br />
                        <div class="row section-header-row" *ngIf="currentQuoteIdentifier">
                            <div class="section-header">Attachments</div>
                        </div>
                        <div class="row form-row" *ngIf="currentQuoteIdentifier">
                            <div class="col-md-12">
                                <app-quote-attachments [quoteIdentifier]="currentQuoteIdentifier"></app-quote-attachments>
                            </div>
                        </div>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>


    </div>
    <app-loading *ngIf="(isUserLoading$ | async) || (isQuoteLoading$ |async)"></app-loading>
</div>