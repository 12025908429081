<div class="container-fluid vendor-quotes-container">
    <form [formGroup]="vendorQuoteForm" class="vendor-quote-form">
        <div class="row">
            <div class="col-md-1 form-label-div">
                <label class="required-label">
                        <b>Warehouse Name:</b>
                    </label>
            </div>
            <div class="col-md-3 form-group">
                <input type="text" value="" id="warehouseName" formControlName="warehouseName" class="form-control">
                <div *ngIf="warehouseName!.invalid && showValidationMessages && warehouseName!.errors!.required" class="takzin-validation-msg">
                    <div *ngIf="warehouseName!.errors!.required">
                        Warehouse Name is required.
                    </div>
                </div>
            </div>


            <div class="col-md-1 form-label-div">
                <label class="required-label">
                        <b>Vendor Name:</b>
                    </label>
            </div>
            <div class="col-md-3 form-group">
                <input type="text" value="" id="vendorName" formControlName="vendorName" class="form-control">
                <div *ngIf="vendorName!.invalid && showValidationMessages && vendorName!.errors!.required" class="takzin-validation-msg">
                    <div *ngIf="vendorName!.errors!.required">
                        Vendor Name is required.
                    </div>
                </div>
            </div>


            <div class="col-md-1 form-label-div">
                <label class="required-label">
                        <b>Vendor Email:</b>
                    </label>
            </div>
            <div class="col-md-3 form-group">
                <input type="text" value="" id="email" formControlName="email" class="form-control">
                <div *ngIf="email!.invalid && showValidationMessages && email!.errors!" class="takzin-validation-msg">
                    <div *ngIf="email!.errors!.required">
                        Vendor Email is required.
                    </div>
                    <div *ngIf="email!.errors!.pattern">
                        Vendor Email is invalid.
                    </div>
                </div>
            </div>


            <div class="col-md-1 form-label-div">
                <label>
                        <b>Vendor Contact No.:</b>
                    </label>
            </div>
            <div class="col-md-3 form-group">
                <input type="text" value="" id="contactNo" formControlName="contactNo" class="form-control">
            </div>

            <div class="col-md-1 form-label-div">
                <label>
                        <b>Rate CTC:</b>
                    </label>
            </div>
            <div class="col-md-7 form-group">
                <textarea type="text" value="" id="rateCtc" formControlName="rateCtc" class="form-control"></textarea>
            </div>

            <div class="col-md-1 form-label-div">
                <label>
                        <b>Rate Quoted:</b>
                    </label>
            </div>
            <div class="col-md-3 form-group">
                <input type="text" value="" id="rateQuoted" formControlName="rateQuoted" class="form-control">
            </div>

            <div class="col-md-2">
                <button type="button" (click)=onSubmit(vendorQuoteForm.value) class="takzin-button-primary takzin-modal-button">Save</button>
                <button type="button" (click)=onReset() class="takzin-button-secondary takzin-modal-button">Reset</button>
            </div>
        </div>
    </form>

    <form [formGroup]="editVendorQuoteForm" class="vendor-quote-form">
        <div class="row" *ngFor='let vendorQuote of vendorQuotes$ | async'>
            <div class="col-md-12">
                <hr />
            </div>
            <div class="col-md-1 form-label-div">
                <label class="required-label">
                        <b>Warehouse Name:</b>
                    </label>
            </div>
            <div class="col-md-3 form-group" *ngIf="vendorQuote.id === currentVendorQuoteId">
                <input type="text" value="{{vendorQuote.warehouseName}}" id="editWarehouseName" formControlName="editWarehouseName" class="form-control">
                <div *ngIf="editWarehouseName!.invalid && showEditValidationMessages && editWarehouseName!.errors!.required" class="takzin-validation-msg">
                    <div *ngIf="editWarehouseName!.errors!.required">
                        Warehouse Name is required.
                    </div>
                </div>
            </div>
            <div class="col-md-3 form-group" *ngIf="vendorQuote.id !== currentVendorQuoteId">
                {{vendorQuote.warehouseName}}
            </div>


            <div class="col-md-1 form-label-div">
                <label class="required-label">
                        <b>Vendor Name:</b>
                    </label>
            </div>
            <div class="col-md-3 form-group" *ngIf="vendorQuote.id === currentVendorQuoteId">
                <input type="text" value="{{vendorQuote.vendorName}}" id="editVendorName" formControlName="editVendorName" class="form-control">
                <div *ngIf="editVendorName!.invalid && showEditValidationMessages && editVendorName!.errors!.required" class="takzin-validation-msg">
                    <div *ngIf="editVendorName!.errors!.required">
                        Vendor Name is required.
                    </div>
                </div>
            </div>
            <div class="col-md-3 form-group" *ngIf="vendorQuote.id !== currentVendorQuoteId">
                {{vendorQuote.vendorName}}
            </div>


            <div class="col-md-1 form-label-div">
                <label class="required-label">
                        <b>Vendor Email:</b>
                    </label>
            </div>
            <div class="col-md-3 form-group" *ngIf="vendorQuote.id === currentVendorQuoteId">
                <input type="text" value="{{vendorQuote.email}}" id="editEmail" formControlName="editEmail" class="form-control">
                <div *ngIf="editEmail!.invalid && showEditValidationMessages && editEmail!.errors!" class="takzin-validation-msg">
                    <div *ngIf="editEmail!.errors!.required">
                        Vendor Email is required.
                    </div>
                    <div *ngIf="editEmail!.errors!.pattern">
                        Vendor Email is invalid.
                    </div>
                </div>
            </div>
            <div class="col-md-3 form-group" *ngIf="vendorQuote.id !== currentVendorQuoteId">
                {{vendorQuote.email}}
            </div>


            <div class="col-md-1 form-label-div">
                <label>
                        <b>Vendor Contact No.:</b>
                    </label>
            </div>
            <div class="col-md-3 form-group" *ngIf="vendorQuote.id === currentVendorQuoteId">
                <input type="text" value="{{vendorQuote.contactNo}}" id="editContactNo" formControlName="editContactNo" class="form-control">
            </div>
            <div class="col-md-3 form-group" *ngIf="vendorQuote.id !== currentVendorQuoteId">
                {{vendorQuote.contactNo}}
            </div>

            <div class="col-md-1 form-label-div">
                <label>
                        <b>Rate CTC:</b>
                    </label>
            </div>
            <div class="col-md-7 form-group" *ngIf="vendorQuote.id === currentVendorQuoteId">
                <textarea type="text" value="{{vendorQuote.rateCtc}}" id="editRateCtc" formControlName="editRateCtc" class="form-control"></textarea>
            </div>
            <div class="col-md-7 form-group" *ngIf="vendorQuote.id !== currentVendorQuoteId">
                <div class="rateCtc-content">{{vendorQuote.rateCtc}}</div>
            </div>

            <div class="col-md-1 form-label-div">
                <label>
                        <b>Rate Quoted:</b>
                    </label>
            </div>
            <div class="col-md-3 form-group" *ngIf="vendorQuote.id === currentVendorQuoteId">
                <input type="text" value="{{vendorQuote.rateQuoted}}" id="editRateQuoted" formControlName="editRateQuoted" class="form-control">
            </div>
            <div class="col-md-3 form-group" *ngIf="vendorQuote.id !== currentVendorQuoteId">
                {{vendorQuote.rateQuoted}}
            </div>

            <div class="col-md-2" *ngIf="vendorQuote.id !== currentVendorQuoteId">
                <button type="button" (click)=onEdit(vendorQuote) class="takzin-button-primary takzin-modal-button">Edit</button>
                <button type="button" (click)=onDelete(vendorQuote.id) class="takzin-button-secondary takzin-modal-button">Delete</button>
            </div>

            <div class="col-md-4" *ngIf="vendorQuote.id === currentVendorQuoteId">
                <button type="button" (click)=onUpdate(vendorQuote.id,editVendorQuoteForm.value) class="takzin-button-primary takzin-modal-button">Update</button>
                <button type="button" (click)=onCancel() class="takzin-button-secondary takzin-modal-button">Cancel</button>
            </div>
        </div>
    </form>
</div>