<div class="login-container">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-4">&nbsp;</div>
            <div class="col-md-4 header-div">
                <h3 class="header-title ">ta<span class="primary-color">ḵ</span>zīn</h3>
                <br />
            </div>

        </div>
        <div class="row">
            <div class="col-md-4">&nbsp;</div>
            <div class="col-md-4 form-container">
                <form [formGroup]="loginForm" class="login-form">
                    <div class="form-group">
                        <label class="takzin-modal-control-label required-label">
                                Email
                            </label>
                        <input type="text" value="" id="email" formControlName="email" class="form-control" required>
                        <div *ngIf="email!.invalid && showValidationMessages" class="takzin-validation-msg">
                            <div *ngIf="email!.errors!.required">
                                Email is required.
                            </div>
                            <div *ngIf="email!.errors!.pattern">
                                Email is invalid.
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label class="takzin-modal-control-label required-label">
                                Password
                            </label>
                        <input type="password" value="" id="password" formControlName="password" class="form-control" required>
                        <div *ngIf="password!.invalid && showValidationMessages" class="takzin-validation-msg">
                            <div *ngIf="password!.errors!.required">
                                Password is required.
                            </div>
                        </div>
                    </div>
                    <br />
                    <button type="button" (click)=onLogin(loginForm.value) class="takzin-button-primary takzin-modal-button">Login</button>
                </form>
            </div>
        </div>
    </div>
</div>