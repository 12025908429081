import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { IUserModel } from "../../models";
import { UserState } from "../reducers";
import * as fromUsers from '../selectors/users.selector';
import * as userActions from "../actions/users.action";

export interface IUsersStoreFacade {
    selectAllUsers$: Observable<IUserModel[]>;
    isUserLoading$: Observable<boolean>;
    getCurrentUser$: Observable<IUserModel>;

    queryUsers(): void;
    createUser(user: IUserModel): void;
    queryUserById(userId: string): void;
    updateUsers(id:string, changes: Partial<IUserModel>): void;
}

@Injectable()
export class UsersStoreFacade implements IUsersStoreFacade {

    get selectAllUsers$() {
        return this.store.select(fromUsers.selectAll);
    }

    get isUserLoading$() {
        return this.store.select(fromUsers.isUserLoading$)
    }

    get getCurrentUser$() {
        return this.store.select(fromUsers.getCurrentUser$)
    }

    constructor(
        private readonly store: Store<UserState>,
    ){}

    queryUsers() {
        this.store.dispatch(userActions.queryUsers());
    }

    createUser(user: IUserModel) {
        this.store.dispatch(userActions.createUser({user}));
    }

    queryUserById(userId: string) {
        this.store.dispatch(userActions.queryUserById({userId}));
    }

    updateUsers(id:string, changes: Partial<IUserModel>){
        this.store.dispatch(userActions.updateUsers({id, changes}));
    }
}