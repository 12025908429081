import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { delay } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { IUpdateQuotesDetailsDto } from "../sales/dtos";
import { IQuotesDataModel, PagedQuoteResponse } from "../sales/models/quotes-data.model";

@Injectable()
export class QuotesService {
    quotesApiBaseUrl: string;

    constructor(private _http: HttpClient,
        private readonly db: AngularFirestore  ) {
        this.quotesApiBaseUrl = environment.quotesApiBaseUrl;
    }

    getAllQuotesFromFireStore(pageNumber: number, pageSize: number)
    {
        return this.db.collection<IQuotesDataModel>('quotes').stateChanges();
    }

    getAllQuotes(pageNumber: number, pageSize: number) {
        let url = this.quotesApiBaseUrl + '/QuoteEnquiry';
        let params = new HttpParams();
        params = params.append('pageNumber', pageNumber.toString());
        params = params.append('pageSize', pageSize.toString());
        return this._http.get<PagedQuoteResponse>(url, {params: params});
    }

    updateQuoteDetailsById(quoteId: string, updateQuotesDetailsDto: IUpdateQuotesDetailsDto){
        let url = this.quotesApiBaseUrl + '/QuoteEnquiry/'+quoteId;
        let httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };

        return this._http.put(url, updateQuotesDetailsDto, httpOptions);
    }
}