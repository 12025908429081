import { isPlatformBrowser } from "@angular/common";
import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable()
export class AuthService{
    constructor(
        private firebaseAuth: AngularFireAuth,
        @Inject(PLATFORM_ID) private readonly platformId: any,
    ) {}

    isAuthenticated() {
        const jwtHelper = new JwtHelperService();
        let user = null;
        if (isPlatformBrowser(this.platformId)) {
            user = JSON.parse(localStorage.getItem('user'));
        }
        let accessToken = user?.stsTokenManager?.accessToken;
        let decodedToken = jwtHelper.decodeToken(accessToken);
        
        if(user && user.uid === decodedToken?.user_id)
        {
            return true;
        }
        this.firebaseAuth.signOut();
        return false;
    }
}