<div class="search-link">
    <app-search></app-search>
    <!-- <mat-paginator [length]="100" [hidePageSize]="isMobileBrowser()" [pageIndex]="(2) - 1" [pageSize]="pageSize$ | async" [pageSizeOptions]="[5, 10, 25]" (page)="onPageChanged($event)">

    </mat-paginator>
    <a (click)="refreshList()" class="refresh-list-link">
        <span class="material-icons">
                                refresh
                            </span>
    </a> -->


</div>
<div class="button-container">
    <button type="button" (click)="addNewQuote()" class="takzin-button-primary takzin-modal-button add-new-quote">Add New Quote</button>
    <button type="button" (click)="next()" class="takzin-button-primary takzin-modal-button next-page" *ngIf="quotes$ | async" [disabled]="(quotes$ | async).length<10">Next</button>
    <button type="button" (click)="prev()" class="takzin-button-primary takzin-modal-button next-page" *ngIf="quotes$ | async" [disabled]="(currentPage$ | async)<2 || (isLoading$ | async)">Prev</button>
    <span class="page-text" *ngIf="!isMobileBrowser()">{{(currentPage$ | async)>0 ? 'Page: '+(currentPage$ | async) : ''}}</span>

</div>
<div class="paging-button-container" *ngIf="!(isUserLoading$ | async)">
    <!-- <button type="button" (click)="prev()" class="takzin-button-primary takzin-modal-button prev-page" [disabled]="(currentPage$ | async) < 2">Prev</button>
    <button type="button" (click)="next()" class="takzin-button-primary takzin-modal-button next-page" *ngIf="quotes$ | async">Next</button> -->
</div>
<mat-table [dataSource]="quotes$" class="quotes-table" *ngIf="!(isUserLoading$ | async)">
    <ng-container matColumnDef="quoteId">
        <mat-header-cell *matHeaderCellDef> Quote Id </mat-header-cell>
        <mat-cell *matCellDef="let element"> <a (click)="editQuote(element.id, element.quoteId)" class="quotes-link">{{element.quoteId}}</a> </mat-cell>
    </ng-container>

    <ng-container matColumnDef="assignedTo">
        <mat-header-cell *matHeaderCellDef> Assigned To </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{getUserDisplayName(element.assignedTo,(allUsers$ | async))}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.name}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="companyName">
        <mat-header-cell *matHeaderCellDef> Company Name </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.companyName}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.status ? statusDesc[element.status] : statusDesc['new']}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="createdWhen">
        <mat-header-cell *matHeaderCellDef> Date of Enquiry </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{getFormattedDate(element.createdDate)}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="aging">
        <mat-header-cell *matHeaderCellDef> Age of Enquiry </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{getAge(element.createdDate)}} day(s) </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let myRowData; columns: displayedColumns"></mat-row>
</mat-table>
<app-loading *ngIf="(isLoading$ | async) || (isUserLoading$ | async)"></app-loading>
<br />
<div *ngIf="noResult$ | async" class="no-results">No Results Found. Please click on <a (click)="onResetClick()" class="search-reset">Reset</a> to refresh the page and search filters.</div>