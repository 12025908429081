import { createEntityAdapter, Dictionary, EntityState } from "@ngrx/entity";
import { Action, createReducer, on } from "@ngrx/store";
import { IUserModel } from "../../models";
import { addedUsers, modifiedUsers, noUserResult, queryUserById, queryUserByIdSuccess, queryUsers, removedUser, updateUsers, userError } from "../actions";

export function getUsersReducer(state: UserState | undefined, action: Action) {
    return usersReducer(state, action);
}

export const userAdapter = createEntityAdapter<IUserModel>();

export interface UserState extends EntityState<IUserModel> {
    entities: Dictionary<IUserModel>;
    error: any;
    noUserResult: boolean;
    userLoading: boolean;
    currentUser: IUserModel;
}

export const initialUserState: UserState = userAdapter.getInitialState({
    entities: [],
    error: null,
    noUserResult: false,
    userLoading: false,
    currentUser: null
});

export const usersReducer = createReducer(
    initialUserState,
    on(queryUsers, (state) => {
        return  {
            ...initialUserState,
            userLoading: true
        };
    }),

    on(queryUserById, (state, {userId}) => {
        return  {
            ...state,
            userLoading: true
        };
    }),

    on(queryUserByIdSuccess, (state, {user}) => {
        return  {
            ...state,
            userLoading: false,
            currentUser: user
        };
    }),

    on(addedUsers, (state, { user }) => {
        return userAdapter.addOne(user, {
            ...state,
            userLoading: false
        });
    }),

    on(modifiedUsers, (state, { user }) => {
        return userAdapter.updateOne({
            id: user.id,
            changes: user
        }, {
            ...state,
            userLoading: false
        })
    }),

    on(removedUser, (state, { user }) => {
        return userAdapter.removeOne(user.id, state);
    }),

    on(userError, (state, { error }) => {
        return  {
            ...state,
            error: 'There was something wrong. Please refresh the page and try again.'
        };
    }),

    on(noUserResult, (state) => {
        return {
            ...state,
            noResult: true
        }
    }),
    
    on(updateUsers, (state, { id, changes }) => {
        return {
            ...state,
            error: null,
            noResult: false
        }
    }),
);