import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { of } from "rxjs";
import { QuotesStoreFacade } from "../facades";
import * as quoteActions from "../actions/quotes.action";
import { AngularFirestore } from "@angular/fire/firestore";
import { IQuotesDataModel } from "../../models";

@Injectable()
export class QuotesEffects{
    constructor(
        private readonly actions$: Actions,
        private readonly quotesFacade: QuotesStoreFacade,
        private readonly dfs: AngularFirestore
    ){}

    queryQuotesById$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(quoteActions.queryQuotesById),
            withLatestFrom(this.quotesFacade.getFilter$),
            switchMap(([action, filter]) => {
                let dbInstance = this.dfs.collection<IQuotesDataModel>('quotes', ref => {
                    return ref.where('id', '==', action.id);
                })
                return dbInstance.stateChanges();
            
            }),
            mergeMap(actions => actions),
            map(action => {
                if(action.type == 'added')
                {
                    return quoteActions.queryQuotesByIdSuccess({quote: action.payload.doc.data()})
                }
                if(action.type == 'modified')
                {
                    return quoteActions.queryQuotesByIdSuccess({quote: action.payload.doc.data()})
                }

                return {
                    type: `[Quotes] ${action.type}`,
                    payload: {
                        quote: action.payload.doc.data()
                    }
                }
                 
            })
        )
    });

    queryQuotes$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(quoteActions.queryQuotes),
            withLatestFrom(this.quotesFacade.getFilter$, this.quotesFacade.currentPage$, 
                this.quotesFacade.lastQuoteCurrentPage$, this.quotesFacade.firstQuoteCurrentPage$,
                this.quotesFacade.nextPage$),
            switchMap(([action, filter, page, lastQuote, firstQuote, nextPage]) => {
                // console.log(action);
                let response = this.dfs.collection<IQuotesDataModel>('quotes', (ref) => {
                    let ref2: any = ref;
                    if(filter?.assignedTo)
                    {
                        ref2 = ref2.where('assignedTo', '==', filter.assignedTo);
                    }

                    if(filter?.quoteId)
                    {
                        ref2 = ref2.where('quoteId', '==', filter.quoteId);
                    }
                    
                    if(filter?.quoteType)
                    {
                        ref2 = ref2.where('quoteType', '==', filter.quoteType);
                    }

                    if(filter?.status)
                    {
                        ref2 = ref2.where('status', '==', filter.status);
                    }

                if(nextPage)
                {
                    if(page < 2)
                    {
                        return ref2.orderBy('createdDate', 'desc').limit(10);
                    }
                    else{
                        return ref2.orderBy('createdDate', 'desc').startAfter(lastQuote.createdDate).limit(10);
                    }
                }
                else {
                    return ref2.orderBy('createdDate', 'desc').endBefore(firstQuote.createdDate).limitToLast(10);
                }
                    
                }).stateChanges()
                return response;
            }),
            mergeMap(actions => {
                if(actions?.length > 0)
                {
                    return actions;
                }

                return of(quoteActions.noResult())
                
            }),
            map(action => {
                console.log(action)
                if(action.type == 'added')
                {
                    return quoteActions.addedQuotes({quote: action.payload.doc.data()})
                }
                if(action.type == 'modified')
                {
                    return quoteActions.modifiedQuotes({quote: action.payload.doc.data()})
                }
                if(action.type == 'removed')
                {
                    return quoteActions.removedQuotes({quote: action.payload.doc.data()})
                }
                return {
                    type: `${action.type}`,
                }
                 
            }),
            catchError((error) => {
                console.log(error);
                return of(quoteActions.error({error: 'error'}))
            })
        )
    });

    updateQuote$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(quoteActions.updateQuotes),
            switchMap(data => {
                const ref = this.dfs.doc<IQuotesDataModel>(`quotes/${data.id}`)
                return ref.update(data.changes)
                .then(() => {
                    return quoteActions.queryQuotes()
                })
                .catch((error) => {
                    console.log(error)
                    return quoteActions.error({error: 'error'})
                })
            }),
            catchError((error) => {
                console.log(error);
                return of(quoteActions.error({error: 'error'}))
            })
        )
    });

    createQuote$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(quoteActions.createQuote),
            switchMap(data => {
                return this.dfs.collection('quotes').doc(data.quote.id).set(Object.assign({}, data.quote))
            }),
            map(() => quoteActions.queryQuotes()),
            catchError((error) => {
                console.log(error);
                return of(quoteActions.error({error: 'error'}))
            })
        )
    });

    nextPage$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(quoteActions.nextPage),
            switchMap(action => {
                return of(quoteActions.queryQuotes())
            }),
            catchError((error) => {
                console.log(error);
                return of(quoteActions.error({error: 'error'}))
            })
        )
    })

    prevPage$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(quoteActions.prevPage),
            switchMap(action => {
                return of(quoteActions.queryQuotes())
            }),
            catchError((error) => {
                console.log(error);
                return of(quoteActions.error({error: 'error'}))
            })
        )
    })

    addedQuotes$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(quoteActions.addedQuotes),
            switchMap(action => {
                // console.log('addedLast')
                // console.log(action.quote);
                return of(quoteActions.lastQuoteInPage({quote: action.quote}))
            }),
            catchError((error) => {
                console.log(error);
                return of(quoteActions.error({error: 'error'}))
            })
        )
    })

    deleteQuotes$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(quoteActions.deleteQuotes),
            switchMap(data => {
                const ref = this.dfs.doc<IQuotesDataModel>(`quotes/${data.id}`)
                return ref.delete()
                .then(() => {
                    return quoteActions.deleteQuotesSuccess()
                })
                .catch((error) => {
                    console.log(error);
                    return quoteActions.error({error: 'error'})
                })
            }),
            catchError((error) => {
                console.log(error);
                return of(quoteActions.error({error: 'error'}))
            })
        )
    });
}
