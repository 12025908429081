import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators'
import { UUID } from 'angular2-uuid';
import { IQuotesDataModel } from '../../models/quotes-data.model';
import { QuotesStoreFacade } from '../../store/facades';
import firebase from 'firebase/app';
import Timestamp = firebase.firestore.Timestamp;
import { formatDate } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { IUserModel } from '../../models';
import { UsersStoreFacade } from '../../store/facades/users.store-facade';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmBoxComponent } from 'src/app/shared/components';

@Component({
  selector: 'takzin-quotes-details',
  templateUrl: './quotes-details.component.html',
  styleUrls: ['./quotes-details.component.scss']
})
export class QuotesDetailsComponent implements OnInit {

  public quoteDetail: IQuotesDataModel;
  public quoteDetailsForm: FormGroup;
  public quoteType: string;
  public minDate = new Date();
  public quoteId: string;
  public newQuoteForm = false;;
  public currentUser: IUserModel;
  public isUserLoading$: Observable<boolean>;
  public isQuoteLoading$: Observable<boolean>;
  public currentQuoteIdentifier: string;
  public allUsers$: Observable<IUserModel[]>;
  public isAdminUser: boolean;

  public dateTimeFormatOptions = { month: "long", hour: "numeric", minute: "numeric", day: "numeric", year: "numeric" } as const;
  public locale = 'en-AE';
  public showValidationMessages = false;
  public tabColor = '#458FF6';

  public handlingValues: [
    'Select your option',
    'CBM',
    'Pallet'
  ];

  get name() {
    return this.quoteDetailsForm!.get('name');
  }

  get email() {
    return this.quoteDetailsForm!.get('email');
  }

  get companyName() {
    return this.quoteDetailsForm!.get('companyName');
  }

  get assignedTo() {
    return this.quoteDetailsForm!.get('assignedTo');
  }

  get emirate() {
    return this.quoteDetailsForm!.get('emirate');
  }

  get region() {
    return this.quoteDetailsForm!.get('region');
  }

  get vehicleType() {
    return this.quoteDetailsForm!.get('vehicleType');
  }

  get dropLocation() {
    return this.quoteDetailsForm!.get('dropLocation');
  }

  get pickupLocation() {
    return this.quoteDetailsForm!.get('pickupLocation');
  }

  get contactNumber() {
    return this.quoteDetailsForm!.get('contactNumber');
  }

  get quoteTypeControl() {
    return this.quoteDetailsForm!.get('quoteTypeControl');
  }

  get quoteSource() {
    return this.quoteDetailsForm!.get('quoteSource');
  }

  get item() {
    return this.quoteDetailsForm!.get('item');
  }

  get storage() {
    return this.quoteDetailsForm!.get('storage');
  }

  get handling() {
    return this.quoteDetailsForm!.get('handling');
  }

  get duration() {
    return this.quoteDetailsForm!.get('duration');
  }

  get status() {
    return this.quoteDetailsForm!.get('status');
  }

  get from() {
    return this.quoteDetailsForm!.get('from');
  }

  get to() {
    return this.quoteDetailsForm!.get('to');
  }

  get pickupDate() {
    return this.quoteDetailsForm!.get('pickupDate');
  }

  constructor(
    private readonly quotesFacade: QuotesStoreFacade,
    private readonly router: Router,
    private readonly _activatedroute: ActivatedRoute,
    private readonly userFacade: UsersStoreFacade,
    private readonly dialog: MatDialog
  ) {

  }

  ngOnInit(): void {
    this.newQuoteForm = true;
    this.initializeForm();
    this._activatedroute.queryParams.pipe(
      filter(params => params.quoteIdentifier)
    )
      .subscribe(params => {

        this.currentQuoteIdentifier = params.quoteIdentifier;
        // this.currentQuoteIdentifier = this._activatedroute.snapshot.paramMap.get('quoteIdentifier');
        if (this.currentQuoteIdentifier) {
          this.quotesFacade.queryQuoteById(this.currentQuoteIdentifier);
          this.isQuoteLoading$ = this.quotesFacade.isLoadingQuotes$;
        
          this.newQuoteForm = false;
          this.quotesFacade.currentQuote$.subscribe((data) => {
            if (data) {
              this.quoteId = data.quoteId;
              this.quoteDetail = data;
              this.initializeForm();
              if(this.quoteDetail?.assignedTo)
              {
                this.assignedTo?.setValue(this.quoteDetail.assignedTo);
              }
            }
          })
        }
        else {
          this.newQuoteForm = true;
          this.initializeForm();
        }
      }
      );
    let loggedInUser = JSON.parse(localStorage.getItem('user'));
    this.userFacade.queryUsers();
    this.isUserLoading$ = this.userFacade.isUserLoading$;
    this.allUsers$ = this.userFacade.selectAllUsers$;
    this.allUsers$.subscribe((data) => {
      if (data) {
        this.currentUser = data.filter((user) => user.id == loggedInUser.uid)[0]
        if(this.currentUser)
        {
          this.isAdminUser = this.currentUser?.roles?.includes('admin');
        }
      }
      if(this.quoteDetail?.assignedTo)
      {
        this.assignedTo?.setValue(this.quoteDetail.assignedTo);
      }
    })
  }

  onSubmit(selectedData) {
    this.showValidationMessages = false;
    if (this.quoteDetailsForm.invalid) {
      this.showValidationMessages = true;
      return;
    }

    const confirmDialog = this.dialog.open(ConfirmBoxComponent, {
      disableClose: false,
    });
    confirmDialog.componentInstance.confirmMessageHeader = `Save Details`;
    confirmDialog.componentInstance.confirmMessageContent = `Are you sure you want to continue?`;
    confirmDialog.afterClosed().subscribe(result => {
      if (result) {
        if (this.currentQuoteIdentifier) {
          this.updateQuotes(selectedData);
        }
        else {
          this.addQuote(selectedData);
        }
        this.quotesFacade.resetPage();
        this.quotesFacade.selectQuoteId(null);
        this.router.navigate(['/']);
      }
    });

    
  }

  onCancelClick() {
    this.quotesFacade.selectQuoteId(null);
    this.quotesFacade.resetPage();
    this.router.navigate(['/']);
  }

  getFormattedDate(timestamp: Timestamp) {
    if (timestamp) {
      const date = new Date(formatDate(timestamp.toDate(), 'medium', this.locale));
      date.setHours(date.getHours() + 4);
      return date.toLocaleString(this.locale, this.dateTimeFormatOptions);
    }
    var dateinDubai = new Date(new Date().toUTCString().substr(0, 25));
    dateinDubai.setHours(dateinDubai.getHours() + 4);

    return dateinDubai.toLocaleString(this.locale, this.dateTimeFormatOptions);
  }

  getFormattedShortDate(timestamp: Timestamp) {
    const date = formatDate(timestamp.toDate(), 'medium', this.locale);
    let dateTimeFormatOptions = { month: "long", day: "numeric", year: "numeric" } as const;
    return new Date(date).toLocaleString(this.locale, dateTimeFormatOptions);
  }

  clearDate(field: string) {
    if (field == 'from') {
      this.from.setValue(null);
    }

    if (field == 'to') {
      this.to.setValue(null);
    }

    if (field == 'pickup') {
      this.pickupDate.setValue(null);
    }
  }

  selectQuoteType() {
    this.quoteType = this.quoteTypeControl.value;
    const quoteSource = this.quoteSource.value;
    if (this.quoteType == 'storage' && quoteSource !== 'Select your option') {
      this.quoteId = this.getQuoteId(this.quoteType, quoteSource)
      this.vehicleType.clearValidators();
      this.pickupLocation.clearValidators();
      this.dropLocation.clearValidators();
      this.emirate.setValidators([Validators.required]);
      this.region.setValidators([Validators.required]);
      this.item.setValidators([Validators.required]);
      this.storage.setValidators([Validators.required]);
      this.handling.setValidators([Validators.required]);
    }
    if (this.quoteType == 'transport' && quoteSource !== 'Select your option') {
      this.quoteId = this.getQuoteId(this.quoteType, quoteSource)
      this.vehicleType.setValidators([Validators.required]);
      this.pickupLocation.setValidators([Validators.required]);
      this.dropLocation.setValidators([Validators.required]);
      this.emirate.clearValidators();
      this.region.clearValidators();
      this.item.clearValidators();
      this.storage.clearValidators();
      this.handling.clearValidators();
    }
    this.vehicleType.updateValueAndValidity();
    this.pickupLocation.updateValueAndValidity();
    this.dropLocation.updateValueAndValidity();
    this.emirate.updateValueAndValidity();
    this.region.updateValueAndValidity();
    this.item.updateValueAndValidity();
    this.storage.updateValueAndValidity();
    this.handling.updateValueAndValidity();
  }

  filterUserWithRole(users: IUserModel[], role :string){
    let filteredUsers = users.filter((data) =>  data.roles.includes(role))
    return filteredUsers;
  }

  private initializeForm() {
    this.quoteType = this.quoteDetail?.quoteType;
    this.quoteDetailsForm = new FormGroup({
      name: new FormControl(this.quoteDetail?.name, []),
      email: new FormControl(this.quoteDetail?.email, [
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')
      ]),
      companyName: new FormControl(this.quoteDetail?.companyName, []),
      assignedTo: new FormControl(this.quoteDetail?.assignedTo ?? 'Select your option', []),
      emirate: new FormControl(this.quoteDetail?.emirate, []),
      region: new FormControl(this.quoteDetail?.area, []),
      land: new FormControl(this.quoteDetail?.land ?
        this.quoteDetail?.land
        :
        'Select your option', []),
      valueAdded: new FormControl(this.quoteDetail?.valueAdded ?
        this.quoteDetail?.valueAdded
        :
        'Select your option', []),
      ctc: new FormControl(this.quoteDetail?.ctc, []),
      costQuoted: new FormControl(this.quoteDetail?.costQuoted, []),
      warehouseName: new FormControl(this.quoteDetail?.warehouseName, []),
      contactNumber: new FormControl(this.quoteDetail?.contactNumber, []),
      palletOrCbmSize: new FormControl(this.quoteDetail?.palletOrCbmSize, []),
      item: new FormControl(this.quoteDetail?.item, []),
      storage: new FormControl(this.quoteDetail?.storage, []),
      handling: new FormControl(this.quoteDetail?.handling?.toLowerCase(), []),
      duration: new FormControl(this.quoteDetail?.duration?.length > 12 ?
        this.quoteDetail?.duration
        :
        'Select your option', []),
      to: new FormControl(this.quoteDetail?.to ?
        new Date(this.getFormattedShortDate(this.quoteDetail?.to))
        :
        '', []),
      from: new FormControl(this.quoteDetail?.from ?
        new Date(this.getFormattedShortDate(this.quoteDetail?.from))
        :
        '', []),
      status: new FormControl(this.quoteDetail?.status ?? 'new', []),
      forecast: new FormControl(this.quoteDetail?.forecast, []),
      pickupLocation: new FormControl(this.quoteDetail?.pickupLocation, []),
      dropLocation: new FormControl(this.quoteDetail?.dropLocation, []),
      pickupDate: new FormControl(this.quoteDetail?.pickupDate ?
        new Date(this.getFormattedShortDate(this.quoteDetail?.pickupDate))
        :
        '', []),
      pickupTime: new FormControl(this.quoteDetail?.pickupTime, []),
      vehicleType: new FormControl(this.quoteDetail?.vehicleType, []),
      userMessage: new FormControl(this.quoteDetail?.userMessage, []),
      volume: new FormControl(this.quoteDetail?.volume, []),
      eta: new FormControl(this.quoteDetail?.eta, []),
      noOfPalletsOrCartons: new FormControl(this.quoteDetail?.noOfPalletsOrCartons, []),
      additionalDetails: new FormControl(this.quoteDetail?.additionalDetails, [])
    })

    if (!this.currentQuoteIdentifier) {
      this.quoteDetailsForm.addControl('quoteTypeControl', new FormControl('Select your option', [
        Validators.required,
        Validators.pattern('^((?!Select your option).)*$'),
      ]));
      this.quoteDetailsForm.addControl('quoteSource',new FormControl('Select your option', [
        Validators.required,
        Validators.pattern('^((?!Select your option).)*$'),
      ]))
    }

  }

  onDelete(quoteDetail)
  {

    const confirmDialog = this.dialog.open(ConfirmBoxComponent, {
      disableClose: false,
    });
    confirmDialog.componentInstance.confirmMessageHeader = `Delete Quote`;
    confirmDialog.componentInstance.confirmMessageContent = `Are you sure you want to continue?`;
    confirmDialog.afterClosed().subscribe(result => {
      if (result) {
        console.log(quoteDetail.id);
        this.quotesFacade.resetPage();
        this.quotesFacade.selectQuoteId(null);
        this.router.navigate(['/']);
      }
    });


    
  }

  private updateQuotes(selectedData) {
    if (this.quoteType == 'storage') {
      this.quotesFacade.updateQuotes(this.quoteDetail.id, {
        emirate: selectedData.emirate,
        area: selectedData.region,
        item: selectedData.item,
        storage: selectedData.storage,
        duration: selectedData.duration == 'Select your option' ? '' : selectedData.duration,
        from: selectedData.from,
        to: selectedData.to,
        assignedTo: selectedData.assignedTo == 'Select your option' ? '' : selectedData.assignedTo,
        handling: selectedData.handling,
        status: selectedData.status,
        name: selectedData.name,
        email: selectedData.email,
        companyName: selectedData.companyName,
        contactNumber: selectedData.contactNumber,
        userMessage: selectedData.userMessage,
        land: selectedData.land == 'Select your option' ? '' : selectedData.land,
        valueAdded: selectedData.valueAdded == 'Select your option' ? '' : selectedData.valueAdded,
        volume: selectedData.volume,
        eta: selectedData.eta,
        noOfPalletsOrCartons: selectedData.noOfPalletsOrCartons,
        additionalDetails: selectedData.additionalDetails
      });
    }
    if (this.quoteType == 'transport') {
      this.quotesFacade.updateQuotes(this.quoteDetail.id, {
        assignedTo: selectedData.assignedTo == 'Select your option' ? '' : selectedData.assignedTo,
        status: selectedData.status,
        name: selectedData.name,
        email: selectedData.email,
        companyName: selectedData.companyName,
        contactNumber: selectedData.contactNumber,
        pickupLocation: selectedData.pickupLocation,
        dropLocation: selectedData.dropLocation,
        pickupDate: selectedData.pickupDate,
        pickupTime: selectedData.pickupTime,
        vehicleType: selectedData.vehicleType,
        userMessage: selectedData.userMessage,
        volume: selectedData.volume,
        eta: selectedData.eta,
        noOfPalletsOrCartons: selectedData.noOfPalletsOrCartons,
        additionalDetails: selectedData.additionalDetails
      });
    }

  }

  private addQuote(selectedData) {
    let id = UUID.UUID();
    let newQuote: IQuotesDataModel;
    if (this.quoteType == 'storage') {
      newQuote = {
        id: id,
        quoteType: 'storage',
        createdDate: firebase.firestore.Timestamp.fromDate(new Date(new Date().toUTCString().substr(0, 25))),
        quoteId: this.quoteId,
        emirate: selectedData.emirate,
        area: selectedData.region,
        item: selectedData.item,
        storage: selectedData.storage,
        duration: selectedData.duration == 'Select your option' ? '' : selectedData.duration,
        from: selectedData.from,
        to: selectedData.to,
        assignedTo: selectedData.assignedTo == 'Select your option' || !(selectedData?.assignedTo) ? '' : selectedData.assignedTo,
        handling: selectedData.handling,
        status: selectedData.status,
        name: selectedData.name,
        email: selectedData.email,
        companyName: selectedData.companyName,
        contactNumber: selectedData.contactNumber,
        userMessage: selectedData.userMessage,
        land: selectedData.land == 'Select your option' ? '' : selectedData.land,
        valueAdded: selectedData.valueAdded == 'Select your option' ? '' : selectedData.valueAdded,
        volume: selectedData.volume,
        eta: selectedData.eta,
        noOfPalletsOrCartons: selectedData.noOfPalletsOrCartons,
        additionalDetails: selectedData.additionalDetails,
        createdBy: this.currentUser.id
      }
    }

    if (this.quoteType == 'transport') {
      newQuote = {
        id: id,
        quoteType: 'transport',
        createdDate: firebase.firestore.Timestamp.fromDate(new Date(new Date().toUTCString().substr(0, 25))),
        quoteId: this.quoteId,
        assignedTo: selectedData.assignedTo,
        status: selectedData.status,
        name: selectedData.name,
        email: selectedData.email,
        companyName: selectedData.companyName,
        contactNumber: selectedData.contactNumber,
        pickupLocation: selectedData.pickupLocation,
        dropLocation: selectedData.dropLocation,
        pickupDate: selectedData.pickupDate,
        pickupTime: selectedData.pickupTime,
        vehicleType: selectedData.vehicleType,
        userMessage: selectedData.userMessage,
        volume: selectedData.volume,
        eta: selectedData.eta,
        noOfPalletsOrCartons: selectedData.noOfPalletsOrCartons,
        additionalDetails: selectedData.additionalDetails
      };
    }

    this.quotesFacade.createQuote(newQuote);

  }

  private getQuoteId(quoteType, quoteSource): string {
    let quoteprefix = ''
    if(quoteType == 'storage')
    {
      switch(quoteSource) {
        case 'website':{
          quoteprefix = 'tz-';
          break;
        }
        case 'whatsapp':{
          quoteprefix = 'wts-';
          break;
        }
        case 'contact':{
          quoteprefix = 'cts-';
          break;
        }
        default:
          break;
      }
    }
    else{
      switch(quoteSource) {
        case 'website':{
          quoteprefix = 'tr-';
          break;
        }
        case 'whatsapp':{
          quoteprefix = 'wtr-';
          break;
        }
        case 'contact':{
          quoteprefix = 'ctr-';
          break;
        }
        default:
          break;
      }
    }
    return quoteprefix + this.getRandomNumber();
  }

  private getRandomNumber(): number{
    return Math.floor(Math.random() * 1000000000);
  }

}
