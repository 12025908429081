<div class="vendor-link-container">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-4">&nbsp;</div>
            <div class="col-md-4 header-div">
                <h3 class="header-title ">ta<span class="primary-color">ḵ</span>zīn</h3>
                <br />
            </div>

        </div>
        <div class="row">
            <div class="col-md-4">&nbsp;</div>
            <div class="col-md-4 form-container">
                <form [formGroup]="vendorLinkForm" class="vendor-link-form">
                    <div class="form-group">
                        <label class="takzin-modal-control-label required-label">
                                Enter Vendor Email
                            </label>
                        <input type="text" value="" id="email" formControlName="email" class="form-control" required>
                        <div *ngIf="email!.invalid && showValidationMessages" class="takzin-validation-msg">
                            <div *ngIf="email!.errors!.required">
                                Email is required.
                            </div>
                            <div *ngIf="email!.errors!.pattern">
                                Email is invalid.
                            </div>
                        </div>
                    </div>
                    <div class="form-group" *ngIf="vendorLink && !(isVendorLoading)">
                        <label>
                                <b>Vendor Id:</b> {{vendorLink}}
                            </label>
                    </div>
                    <div class="form-group" *ngIf="isVendorLoading">
                        <div class="loading"></div>
                    </div>
                    <br />
                    <button type="button" (click)=onGenerate(vendorLinkForm.value) class="takzin-button-primary takzin-modal-button">Generate</button>
                    <button type="button" (click)=goBack() class="takzin-button-secondary takzin-modal-button">Done</button>
                </form>
            </div>
        </div>
    </div>
</div>